<template>
  <!--:style="{ transform: `scale(${zoomFactor})` }"  -->
  <div class="main">
    <div class="main_top">
      <div v-if="listQuery.voucherType == 3" class="pic_box" v-loading="loading">
        <a :href="currentFileUrl" v-if="currentFileUrl">
          <el-icon style="color: #17a2b8;margin-right: 3px;"
            ><Folder /></el-icon
          >{{ currentFileName}}
        </a>
        <span v-else>暂无文件</span>
        <div class="pic_mark" v-if="fileList.length > 0">
          <span>{{ currentFileIndex + 1 }}</span> / {{ fileList.length }}
        </div>
      </div>
      <div v-else class="pic_box" v-loading="loading" @mousewheel.stop="onMouseWheel" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseleave="onMouseLeave" @mouseup="onMouseUp">
        <img
          v-show="this.fileList.length ==0"
          :src="currentImageUrl"
          alt="暂无数据"
          id="pic"
          :style="{ transform: imageTransform }"
          ref="imageElement"
        />
        <div class="pic_mark" v-if="imagesList.length > 0">
          <span>{{ currentImageIndex + 1 }}</span> / {{ imagesList.length }}
        </div>
        <div class="pic_mark_type" v-if="currentImageType">
          {{ currentImageType }}
        </div>
      </div>
      <div class="operation_bar">
        <el-button size="small" @click="zoomIn" icon="Plus"></el-button>
        <el-button size="small" @click="zoomOut" icon="Minus"></el-button>
        <el-button
          size="small"
          @click="rotateLeft"
          icon="RefreshLeft"
        ></el-button>
        <el-button
          size="small"
          @click="rotateRight"
          icon="RefreshRight"
        ></el-button>
        <el-button size="small" @click="topClick" icon="CaretTop"></el-button>
        <el-button
          size="small"
          @click="bottomClick"
          icon="CaretBottom"
        ></el-button>
        <el-button
          size="small"
          @click="checkImage"
          icon="FullScreen"
        ></el-button>
      </div>
      <div class="form_style">
        <el-form label-width="90px" size="small">
          <el-form-item label="选择类型"> 
            <el-select v-model="listQuery.voucherType" placeholder="请选择类型" @change="getList" filterable style="width:205px;margin:0">
              <el-option label="凭据" :value="1">
              </el-option>
              <el-option label="附件" :value="2">
              </el-option>
              <el-option label="通用文件" :value="3">
              </el-option>
              <el-option label="银行" :value="4">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="票据类型" v-if="listQuery.voucherType == 1"> 
            <el-select v-model="listQuery.markType" placeholder="请选择票据类型" @change="getList" filterable style="width:205px;margin:0">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期" v-if="listQuery.voucherType != 2"> 
            <el-date-picker
              v-model="listQuery.voucherDateStart"
              type="date"
              placeholder="选择月"
              style="width: 100px;"
              @change="getList"
              value-format="YYYY-MM-DD"
            >
            </el-date-picker
            > - <el-date-picker
              v-model="listQuery.voucherDateEnd"
              type="date"
              placeholder="选择月"
              style="width: 100px;"
              @change="getList"
              value-format="YYYY-MM-DD"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="已生成凭证"> 
              <el-radio-group v-model="listQuery.isVouchered" @change="getList">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <div @keydown.ctrl="handleKeydown" tabindex="1">
      <div class="big_box3 table_height" :style="{maxHeight:contentStyleObj}">
       <!-- <el-button size="small" type="primary" @click="mobanClick" plain style="float:right">凭证模板</el-button> -->
       <el-popover placement="bottom-start" :width="520"  trigger="hover">
        <div style="text-align: center;">
          <h3 style="font-size: 16px;">键盘操作</h3>
          <table cellspacing="0" style="width: 500px !important;margin:0 auto; margin-top: 10px" class="content">
            <tr>
              <td style="width:20%"><strong>快捷键</strong> </td>
              <td style="width:30%"><strong>说明</strong></td>
              <td style="width:20%"><strong>快捷键</strong> </td>
              <td style="width:30%"><strong>说明</strong></td>
            </tr>
            <tr>
              <td>Ctrl+s</td>
              <td>保存</td>
              <td>Ctrl+q</td>
              <td>保存并新增</td>
            </tr>
            <tr>
              <td>空格键</td>
              <td>切换借贷方金额</td>
              <td>=</td>
              <td>自动平衡借贷方金额</td>
            </tr>
            <tr>
              <td>//</td>
              <td>复制第一条摘要</td>
              <td>..</td>
              <td>复制上一条摘要</td>
            </tr>
            <tr>
              <td>Enter</td>
              <td>光标跳转到下一输入框</td>
            </tr>
          </table>
        </div>
        <template #reference>
          <i class="iconfont icon-jianpan" style="padding-right: 10px;color:var(--themeColor,#17a2b8);font-size: 26px;"></i>
        </template>
      </el-popover>
      <!-- 顶部标题 -->
      <div class="top_title clearfix">
        <h5>记账凭证</h5>
        <div v-if="templateType == 1" class="tags_title">[税额抵减]</div>
        <div v-if="templateType == 2" class="tags_title">[农产品加计扣除]</div>
        <div v-if="templateType == 3" class="tags_title">[农产品定额扣除]</div>
        <div class="bottom_time clearfix">
          <div class="top_input3 clearfix fl">
            <h6>记</h6>
            <el-input :disabled="id != 0" v-model="content.voucherNo" size="small" style="width:65px"></el-input>
            <span>号</span>
          </div>
          <div class="top_input2 clearfix fl">
            <h6>日期</h6>
            <!--  format="yyyy-MM-dd" -->
            <el-date-picker v-model="content.voucherDate" size="small" style="width:70%;display:inline-block" :disabled-date="disabledDate" type="date" placeholder="选择日期时间"></el-date-picker>
          </div>
        </div>
        <div class="center_title clearfix">
        </div>
        <div class="right_num clearfix">
          <span>附单据</span>
          <span @click="huifu('a',1)">
            <el-input data-index="a_1" v-model="content.billCount" style="width:28%;" size="small"></el-input>
          </span>
          <span>张</span>
        </div>
      </div>
      <!-- content -->
      <div v-auto-focus="focusCtrl" :data-current="currentIndex" :data-action="actionType" class="content2 clearfix">
        <div class="tops">
          <span class="box_top2" style="border-left: none">
            <h6>摘要</h6>
          </span>
          <span class="box_top3">
            <h6>会计科目</h6>
          </span>
          <span class="box_top" v-if="this.wbStatus">
            <h6>币种</h6>
          </span>
          <span class="box_top" v-if="this.chStatus">
            <h6>存货</h6>
          </span>
          <span class="box_top money_box_top">
            <div>借方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <span class="box_top money_box_top">
            <div>贷方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <div @click="additem()" class="add">
            <i class="iconfont icon-jiahao"></i>
          </div>
        </div>
        <div v-for="(item,indexBig) in items" :key="indexBig" class="bottom clearifx big_box">
          <!-- 摘要 -->
          <div @click="huifu(indexBig,1)" class="common_box2 input-height ccyc">
            <el-input v-if="currentIndex == indexBig + '_1'" :data-index="indexBig + '_1'" v-model="item.summary" @keyup.enter="enter" @focus="focusEnter(item.summary)" @input="copySummayr(item)"></el-input>
            <span v-else class="zy">{{item.summary}}</span>
          </div>
          <!-- 科目 -->
          <div @click="huifu(indexBig,2)" style="position: relative;" class="common_box3 big_boxcode input-height ccyc">
            <qzf-select v-if="currentIndex == indexBig + '_2'" :data-index="indexBig + '_2'"  v-model:signName="item.signName" v-model:subjectId="item.subjectId" v-model:fzhsItemId="item.fzhsId" @success="changeCode($event, item)"></qzf-select>  <!--  @update-enter="enter" -->
            <span v-else class="subjectWidth" style="white-space:break-spaces">{{item.signName}}</span>
            <span style="position: absolute;bottom: 0;left: 0;font-size: 10px;">
              余额：
              <span v-if="item.balance<0" @click="jumpDetailAccount(item)" style="color:#F15A24 !important;cursor: pointer;" class="money">{{item.balance}}</span>
              <span v-else class="money" @click="jumpDetailAccount(item)" style="color:var(--themeColor,#17a2b8);cursor: pointer;">{{item.balance}}</span>
            </span>
          </div>
          <!-- 外币 -->
          <div class="common_box big_boxwb" v-if="wbStatus">
            
            <div v-if="item.wbStatus" class="top_item padding_sty">
              <n-select
                @click="huifu(indexBig,3)"
                :data-index="indexBig + '_3'"
                @update:value="changeWb(item)"
                style="width:70px"
                size="small"
                v-model:value="item.wb"
                filterable
                placeholder="选择外币"
                :options="item.wbOptions"
              />
              <input
                :data-index="indexBig + '_4'"
                @click="huifu(indexBig,4)"
                @change="changeWbAmount(item)"
                @keyup.enter="enter"
                v-model="item.wbAmount"
                class="top_input"
                placeholder="外币金额"
              />
            </div>
            <div v-if="item.wbStatus" class="bottom_rate">
              <label>汇率：</label>
              <input
                :data-index="indexBig + '_5'"
                @click="huifu(indexBig,5)"
                @change="changeWbAmount(item)"
                @keyup.enter="enter"
                v-model="item.rate"
                class="top_input"
              />
            </div>


          </div>
          <!-- 存货 -->
          <div class="common_box big_boxcode input-height" v-if="chStatus">
            <div
              v-if="item.chStatus"
              class="top_item ccyc"
              style="display:block"
            >
              <div class="select_box_big border_none_w" v-if="!this.isOld">
                <el-select
                @change="handleSelect"
                  size="small"
                  v-model="item.inventoryId"
                  style="margin: 0px;"
                  @visible-change="changeInventory(item)"
                  multiple
                  remote
                  :remote-method="remoteMethod"
                  collapse-tags
                  filterable
                  placeholder="请选择">
                  
                  <el-option label="存货名称" disabled>
                    <div>
                      存货名称
                      <span style="margin-left:105px">单位</span>
                      <span style="margin-left:42px">金额</span>
                      <span style="margin-left:58px">数量</span>
                      <span style="margin-left:56px">平均单价</span>
                    </div>
                  </el-option>

                  <el-checkbox-group v-model="item.inventoryId">

                    <el-option
                      v-for="item2 in item.inventorys"
                      :key="item2.id"
                      :label="item2.name2"
                      :value="item2.id">
                      <el-checkbox style="pointer-events: none; width:16px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :label="item2.id">
                       
                      </el-checkbox>
                      <el-tooltip  class="item"  effect="dark" :content="item2.name2" placement="top" >
                          <span style="width: 140px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;">
                            {{item2.name2}}
                          </span> 
                      </el-tooltip>  
                      <span>
                          <el-input size="small" @click.stop placeholder="单价" type="text" style="width:80px;float: right;margin-top:5px;margin-left:10px" v-model="item2.price" @change="changePrice(item2)"/>
                        <el-input size="small" @click.stop placeholder="数量" type="text" style="width:80px;float: right;margin-top:5px" v-model="item2.count" @change="changeCount(item2)"/>
                        <el-input size="small" @click.stop placeholder="金额" type="text" style="width:80px;float: right;margin-left:15px;margin-right: 5px;margin-top:5px" v-model="item2.amount"/>
                        <span style="width:50px;float: right;margin-left:10px">
                          {{item2.unit}}
                        </span>
                      </span>
                      
                    </el-option>
                  </el-checkbox-group>
                  
                </el-select>
                
              </div>
              <div v-else>
                数量:<el-input v-model="item.count" style="height:34px;width:76%;margin-top:5px"></el-input>
                <span class="zy">单价:{{ item.count != 0?(item.type == 1?(item.inAmount/item.count).toFixed(2):(item.outAmount/item.count).toFixed(2)):0}}</span>
              </div>
            </div>

          </div>
          <div @click="huifu(indexBig,9)" class="text_big common_box td-money">
            <div class="big_gsbqw" @click="showInput(item,'in',indexBig)" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_9'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.inAmount" :style="{color:(item.inAmount < 0 ?'red':'#000')}">
                {{(Number(item.inAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
            <input v-show="currentIndex == indexBig + '_9'" :data-index="indexBig + '_9'" @change="changeInput(item,'in')" @blur="inputBlur(item,'in',items)" v-model="item.inAmount" type="text" size="small" @keyup.enter="enter" :style="{color:(item.inAmount < 0 ?'red':'#000')}" @keyup.space="switchType(item,'in',indexBig,9)" @keydown="balanceAmount($event,item,'in')"/>
          </div>
          <div @click="huifu(indexBig,10)" class="text_big common_box td-money">
            <div class="big_gsbqw" @click="showInput(item,'out',indexBig)" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_10'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.outAmount" :style="{color:(item.outAmount < 0 ?'red':'#000')}">
              {{(Number(item.outAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
            <input v-show="currentIndex == indexBig + '_10'" :data-index="indexBig + '_10'" @change="changeInput(item,'out')" @blur="inputBlur(item,'out')" v-model="item.outAmount" type="text" size="small" @keyup.enter="enter" :style="{color:(item.outAmount < 0 ?'red':'#000')}"  @keyup.space="switchType(item,'out',indexBig,10)" @keydown="balanceAmount($event,item,'out')"/>
          </div>
          <div class="close" @click="delitem(indexBig,item.id)">
            <i class="iconfont icon-shanchu"></i>
          </div>
          <div style="left: -28px;" @click="additem(indexBig)" class="add">
            <i class="iconfont icon-jiahao"></i>
          </div>
        </div>
        <div class="bottom clearifx">
          <div class="common_box2">
            <p>合计</p>
          </div>
          <div class="common_box3">
            <p class="infarm_box">{{content.bigwrite}}</p>
          </div>
          <div class="common_box" v-if="this.wbStatus">
            <p class="infarm_box"></p>
          </div>
          <div class="common_box" v-if="this.chStatus">
            <p class="infarm_box"></p>
          </div>
          <div class="text_big common_box td-money">
            <div class="big_gsbqw"  :style="{letterSpacing:widthAmount + 'px'}">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="content.inSumAmount" :style="{color:(content.inSumAmount < 0 ?'red':'#000')}">
                {{(Number(content.inSumAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
          </div>

          <div class="text_big common_box td-money">
            <div class="big_gsbqw"  :style="{letterSpacing:widthAmount + 'px'}">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="content.outSumAmount" :style="{color:(content.outSumAmount < 0 ?'red':'#000')}">
                {{(Number(content.outSumAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- content-end -->
      <!-- 底部信息 -->
      <div class="bottom_input clearfix">
        <div class="left_box fl">
          <div class="item_btn fl clearfix">
            <h6>制单人：</h6>
            <p style="width:50%;"></p>
          </div>
        </div>
        <div class="right_box fr">
          <div class="item_btn fl clearfix">
            <h6>审核人：</h6>
            <p style="width:50%;"></p>
          </div>
        </div>
      </div>
      <!-- 底部信息-end -->
      <div style="width:100%;height:33px">
        <qzf-button jz="false" style="float:left" @success="changeVoucherPre(content)">上一张</qzf-button>
        <qzf-button jz="false" style="float:left" @success="changeVoucherNext(content)">下一张</qzf-button>


        <qzf-button style="float:right;margin-left:10px" type="primary" size="small" v-if="content.checkStatus != 1" @success="handleCreate(content.voucherDate)" :disabled="!$buttonStatus('pz_tj')">(ctrl+q)保存并新增</qzf-button>
        <qzf-button
          jz="false" 
          @success="handleCreate()"
          v-if="content.checkStatus != 1 && listQuery.isVouchered == 1"
          :loading="fullscreenLoading"
          type="primary"
          size="small"
          style="float:right"
          :disabled="!$buttonStatus('pz_tj')"
        >(ctrl+s)保存</qzf-button>
        <!-- <qzf-button jz="false" type="primary" @success="saveTemplate()" size="small" plain style="float:right;margin-left:10px" :disabled="!$buttonStatus('pz_tj')">保存为模板</qzf-button> -->
        <qzf-button style="float:right" v-if="content.id && listQuery.isVouchered == 2" type="primary" @success="keepOn(content.voucherDate,'clearInsertNo')" size="small" :disabled="!$buttonStatus('pz_tj')">继续添加</qzf-button>
      </div>
      </div>
    </div>
     <!-- 图片预览 -->
     <el-image-viewer
        v-if="showImagePreview"
        :zoom-rate="1.2"
        @close="showImagePreview = false"
        :url-list="[currentImageUrl]"
      />
</template>

<script>
import { getVoucherCodeInfo, saveVoucherDgg, voucherInfo, getNewVoucherNo,voucherTemplateList,voucherTemplateSave,voucherTemplateInfo,voucherTemplateDel,voucherNextInfo,voucherQuery } from "@/api/voucher.js";
import {addEightHours} from '@/utils/fun.js'
import imgEmpty from '@/assets/add-ai-voucher-empty.png'

export default {
  name: "addAllVoucher",
  components: {},
  props: {},
  data() {
    return {
      disabledDate:(time) => {
        let t = this.$store.getters['user/comInfo'].period
        let e = t.slice(0,4) + "-" + t.slice(4,6) + "-01"
        // //console.log(new Date(e).getTime(),'new Date(e).getTime()')1648771200000
        // 1648671200000
        return time >= new Date(this.$getLastDay(t)).getTime() || time <= new Date(e).getTime() - 100000000
      },
      listQuery:{
        voucherType:1,
        voucherDateStart:"",
        voucherDateEnd:"",
        isVouchered :"2"
      },
      loading:false,
      zoomFactor: 1,
      isDragging:false,
      dragStartX: 0,
      dragStartY:0,
      imageLeft:0,
      imageTop:0,
      currentFileId:0,
      currentFileIndex:0, 
      currentFileUrl:"",
      currentFileName:"",
      currentImageIndex: 0,
      currentImageId:0,
      // currentImageUrl:"https://file.listensoft.net/mnt/v2/img/nodata.png",
      currentImageUrl: imgEmpty,
      imagesList: [],
      rotate: 0,
      fullscreenLoading: false,
      //键盘跳转参数start
      focusCtrl: '0_1',  // 自动聚焦控制,变动时,123执行自动聚焦指令
      currentIndex: '0_1', // 当前聚焦元素的索引
      actionType: 'next', // 自动聚焦的行为类型
      //键盘跳转参数end
      
      wbOptions:[],
      content: {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: new Date(this.nowTime), //时间
        voucherNo: "", //凭证号
        billCount: 0
      },
      widthAmount: 14,
      voucherTemplateData: [],//凭证模板列表
      items: [
        {
          wbStatus: false,
          chStatus: false,
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
        {
          wbStatus: false,
          chStatus: false,
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
      ],
      wbStatus:false,
      chStatus:false,
      dialogVisible: false,
      dialogFormVisiblemoban: false,
      templateType: 0,
      moban: {
        name: "",
        radio: 0,
      },
      id: 0,
      checkAll: false, //是否全选
      isIndeterminate: false,
      inventorysAll:[],
      biddingStage:[],
      contentStyleObj:{}, //高度变化
      subjectBalanceAmount:'',  //余额
      insertVoucherNo:"",
      isOld:this.$store.getters['user/comInfo'].isOld,
      periodOptions:this.$store.getters['commons/periods'],
      fileList:[],
      showImagePreview:false,
      typeOptions:[
        {
          label:"全部",
          value:""
        },
        {
          label:"增值税普通发票（收入）",
          value:"增值税普通发票（收入）"
        },
        {
          label:"增值税普通发票（成本）",
          value:"增值税普通发票（成本）"
        },
        {
          label:"会费收入",
          value:"会费收入"
        },
        {
          label:"捐赠收入",
          value:"捐赠收入"
        },
        {
          label:"缴税回单",
          value:"缴税回单"
        },
        {
          label:"商品销售收入（普票）",
          value:"商品销售收入（普票）"
        },

        {
          label:"购买公积金",
          value:"购买公积金"
        },
        {
          label:"增值税电子普通发票（收入）",
          value:"增值税电子普通发票（收入）"
        },

        {
          label:"购买社保",
          value:"购买社保"
        },

        {
          label:"取现",
          value:"取现"
        },

        {
          label:"政府补助收入",
          value:"政府补助收入"
        },

        {
          label:"增值税专用发票（收入）",
          value:"增值税专用发票（收入）"
        },
        {
          label:"存现",
          value:"存现"
        },

        {
          label:"银行手续费回单",
          value:"银行手续费回单"
        },

        {
          label:"费用票据",
          value:"费用票据"
        },

        {
          label:"服务成本（专票）",
          value:"服务成本（专票）"
        },

        {
          label:"增值税专用发票（代开收入）",
          value:"增值税专用发票（代开收入）"
        },
        {
          label:"银行收款回单（公司）",
          value:"银行收款回单（公司）"
        },
        {
          label:"银行付款回单（公司）",
          value:"银行付款回单（公司）"
        },
        {
          label:"银行付款回单（个人）",
          value:"银行付款回单（个人）"
        },
        {
          label:"购进固定资产",
          value:"购进固定资产"
        },
        {
          label:"增值税专用发票（成本）",
          value:"增值税专用发票（成本）"
        },
        {
          label:"银行利息回单",
          value:"银行利息回单"
        },
        {
          label:"人员工资单",
          value:"人员工资单"
        },
        {
          label:"其他类型",
          value:"其他类型"
        },
        {
          label:"增值税电子发票（收入）",
          value:"增值税电子发票（收入）"
        },
        {
          label:"购进固定资产（专票）",
          value:"购进固定资产（专票）"
        },
        {
          label:"提供服务收入",
          value:"提供服务收入"
        },
        {
          label:"银行收款回单（个人）",
          value:"银行收款回单（个人）"
        },
        {
          label:"增值税电子专用发票（收入）",
          value:"增值税电子专用发票（收入）"
        },
        {
          label:"服务成本（普票）",
          value:"服务成本（普票）"
        },
        {
          label:"增值税费用专票",
          value:"增值税费用专票"
        },
      ]
    };
  },
  computed: {
    imageTransform() {
      return `translate(${this.imageLeft}px, ${this.imageTop}px) scale(${this.zoomFactor})`;
    },
  },
  mounted() {
    this.contentStyleObj=this.$getHeight(540)
    this.content.voucherDate = new Date(this.nowTime)
    let period = this.$store.getters['user/comInfo'].period
    this.getnowTime(period);
    this.init();
    this.getList()
  },
  methods: {
    getList(){
      this.zoomFactor = 1;
      //重置
      this.currentImageId = 0
      this.currentImageIndex = 0;
      this.currentImageUrl = imgEmpty
      this.currentFileIndex = 0;
      this.currentFileId = 0;
      this.currentFileName = '暂无数据'
      this.currentImageUrl = ''
      this.currentImageType = ''
      this.loading = true
      voucherQuery(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.fileList = []
          this.imagesList = []
          if(this.listQuery.voucherType == 3){
            this.fileList = res.data.data.rightList ? res.data.data.rightList : []
            this.currentFileUrl = this.fileList[0]?this.fileList[0].url_img:""
            this.currentFileId = this.fileList[0]?this.fileList[0].id:0
            this.currentFileName = this.fileList[0]?this.fileList[0].fileName:""
          }else{
            this.$refs.imageElement.style.transform = `scale(1) rotate(0deg)`;
            this.imagesList = res.data.data.list ? res.data.data.list : []
            this.currentImageUrl = this.imagesList[0]?this.imagesList[0].url_img:imgEmpty
            this.currentImageId = this.imagesList[0]?this.imagesList[0].id:0
            this.currentImageType = this.imagesList[0]?this.imagesList[0].mark_sort:''
          }
          let id = 0
          if(this.listQuery.isVouchered == '1' && this.listQuery.voucherType == 3){
            id = this.fileList[0] && this.fileList[0].voucher_id ?  this.fileList[0].voucher_id : 0
          }else if(this.listQuery.isVouchered == '1' && this.listQuery.voucherType != 3){
            id = this.imagesList[0] && this.imagesList[0].voucher_id ?  this.imagesList[0].voucher_id : 0
          }
          if(id){
            this.getInfo(id)
          }
        }
      })
    },
    remoteMethod(str){
      let index = this.currentIndex.split("_")[0]
      let arr = [] 
      this.items[index].inventorysBf.map(v=>{
        let s = false
        this.items[index].inventoryId.map(v3=>{
          if(v3 == v.id){
            s = true
          }
        })
        if((v.name2.indexOf(str) > -1 && arr.length < 100) || s){
          arr.push(v)
        }
      })
      this.items[index].inventorys = arr
    },
    init(){
      let id = this.$store.getters["commons/params"].addAllVoucherId
      let a = this.$store.getters["commons/params"].insertVoucherNo
      this.insertVoucherNo = a
      this.id = Number(id)
      if(id){
        this.getInfo(id)
      }else{
        this.keepOn()
      }
    },
    initVoucherNo(){
      getNewVoucherNo({}).then(res=>{
        this.content.voucherNo = res.data.data.info
      })
    },
    handleKeydown(e){
      //console.log(e);
      const { altKey, ctrlKey, shiftKey, key, code } = e;
      if(key == 's'){
        e.preventDefault()
        this.compute()
        this.handleCreate()
      }
      if(key == 'q'){
        e.preventDefault()
        this.compute()
        this.handleCreate(this.content.voucherDate)
        this.keepOn()
      }
    },
    keepOn(e,option) {
      if(option){
        this.insertVoucherNo = ''
      }
      let timeNow = new Date(this.nowTime)
      if(e){
        timeNow = e
      }
      this.templateType = 0;
      this.template1 = false;
      (this.content = {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: timeNow, //时间
        voucherNo: "", //凭证号
        billCount: 0
      }),
        (this.items = [
          {
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },
          {
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },
        ]);
        if(this.insertVoucherNo){
          this.content.voucherNo = this.insertVoucherNo.substring(1)
        }else{
          this.initVoucherNo()
        }
    },
    //凭证模板
    mobanClick() {
      voucherTemplateList({}).then(res=>{
        this.voucherTemplateData = res.data.data.list
        this.dialogVisible = true;
      })
    },
    templateChange1() {
      this.items = [
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 1,
          in_type: false,
          out_type: false,
          type: 1,
          summary: "税额抵减",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: "300"
        },
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 5,
          in_type: false,
          out_type: false,
          type: 1,
          summary: "税额抵减",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: "-300"
        }
      ];
      this.compute();
    },
    templateChange3() {
      this.items = [
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 1,
          in_type: false,
          out_type: false,
          type: 0,
          summary: "领用农产品",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: ""
        },
        {
          inventorys: [],
        inventoryIds: [],
          balance: 0,
          inputStatus: 5,
          in_type: false,
          out_type: false,
          type: 0,
          summary: "领用农产品",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: ""
        }
      ];
      this.compute();
    },
    // 凭证模板单选
    templateChange(){
      if(this.templateType == 1){
        this.templateChange1()
      }else if(this.templateType == 2){
        this.templateChange3()
      }
    },
    //保存为模板
    saveTemplate(){
      this.dialogFormVisiblemoban = true
      //凭证模板
      voucherTemplateList({}).then(res=>{
        this.voucherTemplateData = res.data.data.list
      })
    },
    mobanSave(p){
      let param = p
      if(!this.moban.name){
        this.$message.error("请填写模板名称");
        return
      }
      let status = false
      this.voucherTemplateData.forEach(item => {
        if(this.moban.name == item.name){
          status = true
        }
      })
      if(status == true){
        this.$message.error("模板名称重复");
        return
      }
      this.fullscreenLoading = true;
      param.radio = this.moban.radio
      param.name = this.moban.name
      voucherTemplateSave(param).then(res => {
        this.fullscreenLoading = false;
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.dialogFormVisiblemoban = false
          this.$notify({
            title: "成功",
            message: "添加凭证模板成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    //自定义模板选择
    templateInfo(id) {
      this.templateType = 0
      voucherTemplateInfo({id}).then(res => {
        this.dialogVisible = false
        let data = res.data.data;
        if(data.voucherItem != 0){
          data.voucherItem.map(v=>{
            v.id = 0
          })
          this.items = data.voucherItem
          this.compute();
          this.items.map(v=>{
            if (v.type == 1) {
              v.wbAmount = v.inAmountWb;
            } else {
              v.wbAmount = v.outAmountWb;
            }
            v.inventoryId = []
            if(v.inventoryIds){
              v.inventoryIds.map(z=>{
                v.inventoryId.push(z.inventoryId)
              })
            }
            // v.signName = v.subjectName + v.fzhsName
            this.changeCode(v.subjectId + '-' + v.fzhsId, v, "禁止")
          })
        }
      });
    },
    //自定义模板删除
    templateDel(id) {
      this.$confirm('确认删除此模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voucherTemplateDel({id}).then(() => {
          this.mobanClick()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        });
      })
    },
    handleCreate(e){
      if(this.$checkSettleStatus()) return
      let s = ""
      for(let i=0;i<this.items.length;i++){
        if(this.items[i].chStatus && this.$store.getters['user/comInfo'].isOld != 1){
          if((!this.items[i].inventoryId || this.items[i].inventoryId.length == 0)&& this.items[i].signName.indexOf('5') != 0 ){
            this.$message.error("请选择存货")
            return
          }
          let inventoryIds = []
          let sumAmount = 0
          this.items[i].inventorys.map(v=>{
            this.items[i].inventoryId.map(id=>{
              if(id == v.id){
                if(v.count == 0){
                  s = v.name2 + "没有数量"
                }
                sumAmount += v.amount*1
                inventoryIds.push({
                  inventoryId: id * 1,
                  amount: v.amount * 1,
                  count: v.count * 1,
                  unit: v.unit
                })
              }
            })
          })
          this.items[i].inventoryIds = inventoryIds
          if((Number(sumAmount).toFixed(2) != Number(this.items[i].inAmount + this.items[i].outAmount).toFixed(2))&& this.items[i].signName.indexOf('5') != 0){
            this.$message.error("存货金额与借贷金额不符")
            return
          }
        }
      }
      let param = {
        id: this.content.id * 1,
        inSumAmount: Number(Number(this.content.inSumAmount).toFixed(2)),
        outSumAmount: Number(Number(this.content.outSumAmount).toFixed(2)),
        voucherDate: this.content.voucherDate, //时间
        voucherNo: this.content.voucherNo?"记" + this.content.voucherNo:"",
        billCount: this.content.billCount * 1,
        voucherItem: this.items,
        fromType: this.content.fromType,
      };
      //console.log(this.content.voucherDate);
      if(!param.fromType){
        param.fromType = "110"
      }
      param.voucherItem.map(v => {
        v.type = v.type * 1;
        v.outAmount = v.outAmount * 1;
        v.inAmount = v.inAmount * 1;
        v.inAmountWb = v.type == 1 ? v.wbAmount * 1 : 0;
        v.outAmountWb = v.type == 2 ? v.wbAmount * 1 : 0;
        v.rate = v.rate * 1;
        v.price = v.price * 1;
        v.count = v.count * 1;
      });

      //判断是否填写数据 start
      let status = true
      param.voucherItem.map(item => {
        if(item.subjectId != 0 && (item.inAmount != 0 || item.outAmount != 0) && e != "muban") {
          status =false
        }else if(e == "muban" && item.subjectId != 0 ){
          status =false
        }
      })
      if(status){
        this.$notify({
          title: "失败",
          message: "请填写内容",
          type: "error",
          duration: 2000
        });
        return
      }
      //end

      //验证
      if (param.inSumAmount == param.outSumAmount) {
        if (this.templateType == 10) {
          param.type = 0;
        } else {
          param.type = this.templateType
        }
        if(e == 'muban'){
          this.mobanSave(param)
          return
        }
        this.saveVou(param, e)
      } else {
        this.$notify({
          title: "失败",
          message: "借贷金额不平,请正确填写",
          type: "error",
          duration: 2000
        });
      }
    },
    saveVou(param, e){
      param.voucherDate = addEightHours(param.voucherDate)
      if(!param.id){
        param.fromIds = this.listQuery.voucherType == 3 ? [this.currentFileId] : [this.currentImageId]
      }
      this.fullscreenLoading = true;
      saveVoucherDgg(param).then(res => {
        this.fullscreenLoading = false;
        if (res.data.msg == "success") {
          this.$store.dispatch('tagsView/delCachedViewByName', "voucher")
          this.$notify({
            title: "成功",
            message: "凭证保存成功",
            type: "success",
            duration: 2000
          });
          this.getList()
          if(e){
            this.insertVoucherNo = ''
            this.currentIndex = '0_1'
            this.focusCtrl =  '0_1'
            this.keepOn(e)     //保存并新增
            return
          }
          this.getInfo(res.data.data.info.id)
        }
      });
    },
    changeInventory(item){
      // item.inventorys.map(v=>{
      //   v.price = v.price != undefined?v.price*1:0
      //   v.count = v.count != undefined?v.count*1:0
      //   v.amount = v.amount != undefined?v.amount*1:0
      //   v.amount = v.count * v.price
      // })
      let amount = 0 
      item.inventorys.map(v=>{
        item.inventoryId.map(id=>{
          if(v.id == id){
            if(!v.amount){
              v.amount = 0
            }
            amount = Number((amount*1 + v.amount*1)).toFixed(2)
          }
        })
      })
      if(item.type == 2){
        item.outAmount = amount
      } else {
        item.inAmount = amount
      }
      this.compute();
    },
    getInfo(id){
      voucherInfo({ id }).then(res => {
        let voucher = res.data.data.info;
        this.periodOptions[0].options.map(v => {
          if(v.period == voucher.period){
            this.isOld = v.isOld
          }
        })
        voucher.voucherNo = voucher.voucherNo.replace("记", "");
        this.content = voucher;
        let items = voucher.voucherItem;
        this.templateType = voucher.type;
        items.map(v => {
          if (v.type == 1) {
            v.wbAmount = v.inAmountWb;
          } else {
            v.wbAmount = v.outAmountWb;
          }
          v.signName = v.fzhsName? v.subjectName + '--辅助核算：' + v.fzhsName:v.subjectName
          v.inventoryId = []
          if(v.inventoryIds){
            v.inventoryIds.map(z=>{
              v.inventoryId.push(z.inventoryId)
            })
          }
        });
        this.items = items;
        this.compute();
        this.items.map(v=>{
          this.changeCode(v.subjectId + '-' + v.fzhsId, v, "禁止",'edit')
        })
        this.focusCtrl = "0_0" // 自动聚焦控制,变动时,123执行自动聚焦指令
        this.currentIndex = "0_0" // 当前聚焦元素的索引
      });
    },
    //计算外币 存货 单价 汇率
    changeInput(item, type) {
      if (type == "in") {
        //存货外币
        if (item.wbStatus) {
          if (item.wbAmount) {
            item.rate = (item.inAmount / item.wbAmount).toFixed(4);
          }
        }
        if (item.chStatus) {
          if(!item.inventoryId || item.inventoryId.length == 0){
            item.inventorys[0].count = 1
            item.inventorys[0].amount = item.inAmount
            item.inventoryId = [item.inventorys[0].id]
          }
        }
      } else {
        //存货外币
        if (item.wbStatus) {
          if (item.wbAmount) {
            item.rate = (item.outAmount / item.wbAmount).toFixed(4);
          }
        }
        if (item.chStatus) {
          if(!item.inventoryId || item.inventoryId.length == 0){
            item.inventorys[0].count = 1
            item.inventorys[0].amount = item.outAmount
            item.inventoryId = [item.inventorys[0].id]
          }
        }
      }
    },
    changeWbAmount(item) {
      item.type = 1
      item.inAmount = (item.wbAmount * item.rate).toFixed(2);
      this.compute();
    },
    changeWb(item){
      this.$nextTick(()=>{
        item.wbOptions.map(v=>{
          if(item.wb == v.value){
            item.rate = v.rate
          }
        })
      })
      this.enter()
    },
    //选择科目后
    async changeCode(sign, item, from,type){
      if(!sign){
        return
      }
      await getVoucherCodeInfo({id: sign.split('-')[0] * 1, fzhsItemId: sign.split('-')[1] * 1}).then(res=>{
        if(res.data.msg == "success"){
          item.balance = res.data.data.amount
        // 先获取余额
          item.balanceOld = res.data.data.amount
          item.subjectType = res.data.data.subjectType
          let inventory = res.data.data.inventory
          if(item.inventoryIds){
            item.inventoryIds.map(v=>{
              inventory.map(z=>{
                if(v.inventoryId == z.id){
                  z.amount = v.amount
                  z.count = v.count
                }
              })
            })
          }
          if(inventory && inventory.length > 10){
            if(item.inventoryId && item.inventoryId.length != 0){
              let is = []
              item.inventoryId.map(v=>{
                inventory.map(v2=>{
                  if(v == v2.id){
                    is.push(v2)
                  }
                })
              })
              item.inventorys = is
            }else {
              item.inventorys = inventory.slice(0, 100);
            }
          } else {
            // 只有一条的时候默认勾上
            if(inventory && inventory.length == 1){
              item.inventoryId = [inventory[0].id]
            }
            item.inventorys = inventory
          }
          item.inventorysBf = inventory
          if(item.inventorys && item.inventorys.length != 0 && item.inventorys[0].id != 0){
            item.chStatus = true
            this.chStatus = true
            if(item.inventorys.length == 0){
              item.inventoryIds = [item.inventorys[0].id]
            }
          }else{ 
            // 只有一个科目列表有的时候,也为true
            // 新增的时候可以调存货列
            if(!item.id){
              item.chStatus = false
              // this.chStatus = false
            }
            let s = false
            this.items.map(v=>{
              if(v.chStatus){
                s = true
              }
            })
            this.chStatus = s
          }
          item.wb = res.data.data.wb
          if(item.wb && item.wb != "人民币"){
            item.wbStatus = true
            this.wbStatus = true
            //编辑不赋值
            if(type != 'edit'){
              if(res.data.data.wbRate && Number(res.data.data.wbRate)){ //固定汇率
                item.rate = res.data.data.wbRate
              }else{
                res.data.data.wbs.map(v=>{ //浮动汇率
                  if(v.value.indexOf(item.wb) > -1){
                    item.rate = v.rate
                  }
                })
              }
            }
          }
          if(res.data.data.wbs.length != 0){
            item.wbOptions = res.data.data.wbs
          }
          this.changeStatus()
        }
      })
      if(from != "禁止"){
        this.enter()
      }
    },
    //回车跳转
    huifu(index,num){
      this.currentIndex = index + '_' + num
      this.focusCtrl = index + '_' + num
      this.actionType = 'jump'
    },
    switchType(item,type,index,num){
      if(type == 'in'){
        this.currentIndex = index + '_' + (num+1)
        this.focusCtrl = index + '_' + (num+1)
        this.actionType = 'jump'
        if(item.inAmount){
          item.inAmount = item.inAmount.replace(/\s*/g,"")
          item.inAmount = item.inAmount.replace(/,/g,'')
          item.outAmount = item.inAmount
          item.inAmount = ''
        }
        item.type = 1
      }else{
        this.currentIndex = index + '_' + (num-1)
        this.focusCtrl = index + '_' + (num-1)
        this.actionType = 'jump'
        if(item.outAmount){
          item.outAmount = item.outAmount.replace(/\s*/g,"")
          item.outAmount = item.outAmount.replace(/,/g,'')
          item.inAmount = item.outAmount
          item.outAmount = ''
        }
        item.type = 2
      }
    },
    enter(e){
      // e.preventDefault();
      let qian = this.currentIndex.split('_')[0]
      let hou = this.currentIndex.split('_')[1]
      let item = this.items[qian];
      if(hou == 10){
        if(qian == (this.items.length-1)){
          this.additem(qian)
        }
        this.items[qian * 1 + 1].summary = item.summary;
        this.currentIndex = (Number(qian) + 1) + '_1'
        this.focusCtrl = (Number(qian) + 1) + '_1'
        this.actionType = 'jump'
      }else{
        if(item.wbStatus && hou == 2){//外币可填
          this.currentIndex = qian + "_3";
          this.focusCtrl = qian + "_3";
        }else if(hou == 2 || hou == 5){
          // item.chStatus
          if (false) {
            this.currentIndex = qian + "_6";
            this.focusCtrl = qian + "_6";
          } else {
            if (item.outAmount) {
              this.currentIndex = qian + "_10";
              this.focusCtrl = qian + "_10";
            } else {
              item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
            }
          }
        }else{
          if (hou == 9 && !item.inAmount) {
            item.type = 2;
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          } else if (hou == 9 && item.inAmount) {
            if(qian == (this.items.length-1)){
              this.additem(qian)
            }
            this.items[qian * 1 + 1].summary = item.summary;
            this.currentIndex = Number(qian) + 1 + "_1";
            this.focusCtrl = Number(qian) + 1 + "_1";
            this.actionType = "jump";
          } else if(hou == 7){
            item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
          } else {
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          }
        }
      }
      this.changeStatus()
    },
    // 新增 todo
    additem(index) {
      this.items.splice(index + 1, 0, {
        type: 0,
        summary: "",
        subjectId: 0,
        fzhsId: 0,
        signName: "",
        outAmount: "",
        inAmount: "",
        wbAmount: "",
        wb: "",
        rate: "",
        price: "",
        count: ""
      });
    },
    showInput(item, type) {
      // //console.log(type,item);
      if (type == "in") {
        if(item.outAmount){
          item.inAmount = item.outAmount
        }
        item.outAmount = "";
        item.type = 1;
      } else {
        if(item.inAmount){
          item.outAmount = item.inAmount
        }
        item.inAmount = "";
        item.type = 2;
        item.out_type = true;
      }
    },
    compute() {
      // //console.log("计算")

      //计算
      let in_sum_amount = 0;
      let out_sum_amount = 0;

      this.items.map(v => {
        if(v.inAmount){      
          v.inAmount = String(v.inAmount)
          v.inAmount = v.inAmount.replace(/\（/g, "(").replace(/\）/g, ")")
          v.inAmount = v.inAmount.replace(/,/g,'')
          v.inAmount = eval(v.inAmount)
        }
        if(v.outAmount){
          v.outAmount = String(v.outAmount)
          v.outAmount = v.outAmount.replace(/\（/g, "(").replace(/\）/g, ")")
          v.outAmount = v.outAmount.replace(/,/g,'')
          v.outAmount = eval(v.outAmount)
        }
          out_sum_amount += v.outAmount * 1;
          in_sum_amount += v.inAmount * 1;
      });
      in_sum_amount = Number(in_sum_amount).toFixed(2);
      out_sum_amount = Number(out_sum_amount).toFixed(2);
      this.content.inSumAmount = in_sum_amount;
      this.content.outSumAmount = out_sum_amount;
      if (in_sum_amount == out_sum_amount) {
        this.content.bigwrite = this.saveBig(in_sum_amount);
      } else {
        this.content.bigwrite = "";
      }
      //计算余额
      this.items.map(v=>{
        // 下级余额不让显示为0
        if(!v.balanceOld && v.balanceOld != 0){
          v.balance = undefined
        }else{
          v.balance = 0
        }
        let num = 0
        this.items.map((z,)=>{
          if(v.subjectId == z.subjectId && v.fzhsId == z.fzhsId){
            // 用来判断科目是不是第一次出现
            num++
            // 判断下面的那些没选择code的balance
            // 不然会NaN
            if(!v.balanceOld && v.balanceOld != 0){
              v.balance == undefined
            }else{
              // 判断科目和借贷方是否一致
              // 科目借
              if(v.subjectType && v.subjectType == 1){
               if(num == 1){
                  v.balance = Number((v.balanceOld*1 + v.balance*1 + z.inAmount*1 - z.outAmount*1).toFixed(2))
               } else {
                v.balance = Number((v.balance*1 + z.inAmount*1 - z.outAmount*1).toFixed(2))
               }
                
                // 科目贷
              }else if(v.subjectType && v.subjectType == 2){
                if(num == 1){
                  v.balance = Number((v.balanceOld*1 + v.balance*1 + z.outAmount*1 - z.inAmount*1).toFixed(2))
                } else {
                  v.balance = Number((v.balance*1 + z.outAmount*1 - z.inAmount*1).toFixed(2))
                }
                
              }
            }
          }
        })
      })
    },
    delitem(index ) {
      if (this.items.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      this.items.splice(index, 1);
      this.compute();
    },
    inputBlur(item, type) {
      if (isNaN(item.inAmount && item.outAmount)) {
        this.$message({
          type: "error",
          message: "请输入合法金额数"
        });
        return;
      }
      if (type == "in") {
        // //console.log("失焦时");
        if (item.inAmount == "=" || item.inAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
              amounts = amounts - Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
              amounts = amounts + Number(v.outAmount);
            }
          });
          item.inAmount = amounts.toFixed(2);
        }
      } else {
        item.out_type = false;
        if (item.outAmount == "=" || item.outAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            // //console.log(v)
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
              // //console.log(v.inAmount)
              amounts = amounts + Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
              // //console.log(v.outAmount)
              amounts = amounts - Number(v.outAmount);
            }
          });
          item.outAmount = amounts.toFixed(2);
        }
      }
      this.compute();
      // this.enter() //先注释掉跳转 看看有问题嘛
    },
    saveBig(money) {
      let statusFu = false
      if(money < 0){
        money = -money
        statusFu = true
      }
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "圆";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }

      if(statusFu){
        chineseStr = "负" + chineseStr
      }

      return chineseStr;
    },
    changeStatus(){
      if (this.wbStatus && this.chStatus) {
        this.widthAmount = 4;
      } else if (this.wbStatus || this.chStatus) {
        this.widthAmount = 7.5;
      } else {
        this.widthAmount = 14;
      }
    },
    getnowTime(period) {
      let year = period.substring(0, 4);
      let month = period.substring(4, 6);
      let day = 0;
      if (month == 4 || month == 6 || month == 9 || month == 11) {
        day = 30;
      } else if (month == 2) {
        day = 28;
      } else {
        day = 31;
      }
      this.nowTime = year + "-" + month + "-" + day;
      this.listQuery.voucherDateStart = year + "-" + month + "-01"
      this.listQuery.voucherDateEnd = this.nowTime
    },
    changeVoucherPre(content){
      if (content.period == undefined){
        content.period = this.$store.getters['user/comInfo'].period
      }
      let param = {
        id:content.id,
        type:'pre',
        period:content.period
      }
      voucherNextInfo(param).then(res => {
        if(res.data.data.info == null){
          this.$qzfMessage("已经是第一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addAllVoucherId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addAllVoucher")
        this.$router.push({
          path: "/bookkeeps/addAllVoucher",
          name: "addAllVoucher"
        });
        this.init()
      })
    },
    changeVoucherNext(content){
      let param = {
        id:content.id,
        type:'next',
        period:content.period
      }
      voucherNextInfo(param).then(res => {
        if(res.data.data.info == null){
          this.$qzfMessage("已经是最后一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addAllVoucherId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addAllVoucher")
        this.$router.push({
          path: "/bookkeeps/addAllVoucher",
          name: "addAllVoucher"
        });
        this.init()
      })
    },
    handleSelect(value) {
      let arrarar = []
      this.items.map(v => {
        if(v.inventorys){
          this.inventorysAll = v.inventorys
          v.inventorys.map(vv => {
            arrarar.push(vv.id)
          })
          return
        }
      })
        const checkedCount = value.length;
        this.checkAll = checkedCount === this.inventorysAll.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.inventorysAll.length;
    },
    jumpDetailAccount(row){
      this.$store.dispatch("commons/setParam", { paramSubjectId: row.subjectId });
      this.$store.dispatch('tagsView/delCachedViewByName', "accountBook")
      this.$router.push({
        path: "/bookkeeps/accountBook",
        name: "accountBook",
        query: { subjectId: row.subjectId },
      });
    },
    focusEnter(value){
      if(!value){
        let qian = this.currentIndex.split('_')[0]
        let item = this.items[qian];
        if(this.items[0].summary != ''){
          item.summary = this.items[qian*1-1].summary
        }
      }
      return
    },
    balanceAmount(e, item, type){
      if(e.key == '='){ 
        e.preventDefault();
        if (type == "in") {
            let amounts = 0;
            item.inAmount = 0
            this.items.map(v => {
              if (v.inAmount) {
                amounts = amounts - Number(v.inAmount);
              }
              if (v.outAmount) {
                amounts = amounts + Number(v.outAmount);
              }
            });
            item.inAmount = amounts.toFixed(2);       
        } else {
          item.out_type = false;
          item.outAmount = 0
          let amounts = 0;
          this.items.map(v => {
            if (v.inAmount) {
              amounts = amounts + Number(v.inAmount);
            }
            if (v.outAmount) {
              amounts = amounts - Number(v.outAmount);
            }
          });
          item.outAmount = amounts.toFixed(2);
        }
      }
    },
    //摘要快捷键
    copySummayr(item){
      if(item.summary == '//'){
        item.summary = this.items[0].summary
      }else if(item.summary == '..'){
        let qian = this.currentIndex.split('_')[0]
        let item = this.items[qian];
        item.summary = this.items[qian*1-1].summary
      }
    },
    //修改单价
    changePrice(item){
      if(item.count){
        item.amount = Number((item.price * item.count).toFixed(2));
      }else{
        item.amount = item.price * 1;
      }
    },
    //修改数量
    changeCount(item){
      if(item.price){
        item.amount = Number((item.price * item.count).toFixed(2));
      }
    },
    //上部分
    zoomIn() {
      if(this.listQuery.voucherType != 3){
        if (this.zoomFactor < 1.7) {
          this.zoomFactor += 0.1;
        } else {
          this.$qzfMessage("已经放到最大了哦", 1);
        }
      }
    },
    zoomOut() {
      if(this.listQuery.voucherType != 3){
        if (this.zoomFactor > 0.4) {
          this.zoomFactor -= 0.1;
        } else {
          this.$qzfMessage("已经最小了哦", 1);
        }
      }
    },
    rotateLeft() {
      if(this.listQuery.voucherType != 3){
        this.rotate += 90;
        this.$refs.imageElement.style.transform = `scale(${this.zoomFactor}, 1) rotate(${this.rotate}deg)`;
      }
    },
    rotateRight() {
      if(this.listQuery.voucherType != 3){
        this.rotate -= 90;
        this.$refs.imageElement.style.transform = `scale(${this.zoomFactor}, 1) rotate(${this.rotate}deg)`;
      }
    },
    topClick() {
      if(this.listQuery.voucherType == 3){
        if (this.currentFileIndex > 0) {
          this.currentFileIndex = (this.currentFileIndex - 1) % this.fileList.length;
          this.currentFileUrl = this.fileList[this.currentFileIndex].url_img;
          this.currentFileName = this.fileList[this.currentFileIndex].fileName;
          this.currentFileId = this.fileList[this.currentFileIndex].id
          if(this.listQuery.isVouchered == '1'){
            let id = this.fileList[this.currentFileIndex].voucher_id
            this.getInfo(id)
          }
        } else {
          this.$qzfMessage("没有上一张了", 1);
        }
      }else{
        if (this.currentImageIndex > 0) {
          this.currentImageIndex = (this.currentImageIndex - 1) % this.imagesList.length;
          this.currentImageUrl = this.imagesList[this.currentImageIndex].url_img;
          this.currentImageId = this.imagesList[this.currentImageIndex].id
          this.currentImageType = this.imagesList[this.currentImageIndex].mark_sort
          // 重置旋转和缩放
          this.$refs.imageElement.style.transform = `scale(1) rotate(0deg)`;
          this.zoomFactor = 1;
          if(this.listQuery.isVouchered == '1'){
            let id = this.imagesList[this.currentImageIndex].voucher_id
            this.getInfo(id)
          }
        } else {
          this.$qzfMessage("没有上一张了", 1);
        }
      }
      
    },
    bottomClick() {
      if(this.listQuery.voucherType == 3){
        if (this.currentFileIndex < this.fileList.length - 1) {
          this.currentFileIndex = (this.currentFileIndex + 1) % this.fileList.length;
          console.log(this.currentFileIndex);
          this.currentFileUrl = this.fileList[this.currentFileIndex].url_img;
          this.currentFileName = this.fileList[this.currentFileIndex].fileName;
          this.currentFileId = this.fileList[this.currentFileIndex].id
          if(this.listQuery.isVouchered == '1'){
            let id = this.fileList[this.currentFileIndex].voucher_id
            this.getInfo(id)
          }
        } else {
          this.$qzfMessage("没有下一张了", 1);
        }
      }else{
        if (this.currentImageIndex < this.imagesList.length - 1) {
          this.currentImageIndex = (this.currentImageIndex + 1) % this.imagesList.length;
          this.currentImageUrl = this.imagesList[this.currentImageIndex].url_img;
          this.currentImageId = this.imagesList[this.currentImageIndex].id
          this.currentImageType = this.imagesList[this.currentImageIndex].mark_sort
          // 重置旋转和缩放
          this.$refs.imageElement.style.transform = `scale(1) rotate(0deg)`;
          this.zoomFactor = 1;
          if(this.listQuery.isVouchered == '1'){
            let id = this.imagesList[this.currentImageIndex].voucher_id
            this.getInfo(id)
          }
        } else {
          this.$qzfMessage("没有下一张了", 1);
        }
      }
      
    },
    checkImage(){
      if(this.listQuery.voucherType != 3){
        this.showImagePreview = true
      }
    },
    onMouseWheel(event) {
      event.preventDefault();
      const wheelDelta = event.deltaY;
      if (wheelDelta > 0 && this.zoomFactor > 0.4) {
        this.zoomFactor -= 0.1;
      } else if (wheelDelta < 0) {
        this.zoomFactor += 0.1;
      }
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.startImageX = this.imageLeft;
      this.startImageY = this.imageTop;
    },
    onMouseMove(event) {
      event.preventDefault();
      if (this.isDragging) {
        const offsetX = event.clientX - this.dragStartX;
        const offsetY = event.clientY - this.dragStartY;
        this.imageLeft = this.startImageX + offsetX;
        this.imageTop = this.startImageY + offsetY;
      }
    },
    onMouseUp() {
      this.isDragging = false;
    },
    onMouseLeave() {
      this.isDragging = false;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 1250px;
  height: 420px;
  padding: 10px 30px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 10px 15px 0;
}
.main_top {
  display: flex;
}
.pic_box {
  width: 700px;
  height: 400px;
  border: 1px dashed #999;
  text-align: center;
  border-radius:2px;
  position: relative;
  z-index: 1;
}
.pic_mark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  span {
    color: #17a2b8;
  }
}
#pic {
  max-width: 100%;
  max-height: 400px; /* 或者设置固定高度 */
}
.operation_bar {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .el-button {
    margin-left: 0 !important;
  }
}
.form_style {
  width: 400px;
  height: 230px;
  border: 1px dashed #999;
  margin-left: 40px;
  padding-top: 10px;
  border-radius:2px;
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.big_box3 {
  position: relative;
  width: 1250px;
  padding: 10px 30px 0;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  position: relative;
  // min-height: 450px;
  margin: 10px 15px 0 15px;
  .left_sh{
    position: absolute;
    left: 60px;
    top: 20px;
    img{
      width: 100px;
      z-index: 99999999999999;
    }
    .status_true{
      padding: 6px 20px;
      border: 2px solid #67c23a;
      font-size: 12px;
      color: #67c23a;
    }
    .status_false{
      padding: 6px 20px;
      border: 2px solid #f56c6c;
      font-size: 12px;
      color: #f56c6c;
    }
  }
  .moban {
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .arrow_btn {
    // width: 20px;
    // height: 60px;
    text-align: center;
    .two_btn {
      font-size: 20px;
      color: var(--themeColor,#17a2b8);
      line-height: 60px;
      background: #eee;
      cursor: pointer;
    }
    .arrow_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .arrow_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .bottom_time {
    float: left;
    width: 40%;
  }
  .top_input2 {
    width: 50%;
    h6 {
      display: inline-block;
      width: 40px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .top_input3 {
    width: 30%;
    h6 {
      float: left;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-right: 10px;
    }
    span {
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-left: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
}
.zy{
  word-break: normal;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical
}
// 样式  
.el-select-dropdown__item{
    height:37px
  }
.padding_sty {
  border-bottom: 1px solid #eee;
  margin: 5px;
  .top_input {
    width: 50px !important;
    border-bottom: none !important;
  }
}
.top_input {
  width: 100px;
  border-bottom: none !important;
  outline: none;
}
.top_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .num_box_item {
    width: 43%;
    flex: 1;
    margin: 5px;
    display: inline-block;
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start !important;
    // padding: 0 50px 0 10px;
    border-bottom: 1px solid #eee;
    label {
      font-size: 12px;
      color: #333;
      font-weight: normal;
      // flex: 1;
      display: inline-block;
      line-height: 24px;
    }
    input{
      width: 70% !important;
    }
  }


  .top_input {
    flex: 1;
    height: 24px !important;
    display: inline-block;
    background-color: #fff;
    background-image: none;
    // border-radius: 4px;
    // border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    line-height: 24px;
    outline: none;
    padding: 0 6px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .el-select {
    flex: 1;
    display: inline-block;
  }
}
.top_title {
  width: 100%;
  margin: 10px auto;
  position: relative;
  h5 {
    font-size: 24px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .tags_title{
    font-size: 15px !important;
    color: #666;
    margin-left: 50px;
    position: absolute;
    right: 30%;
    top: 10%;
  }
  
}
.center_title {
  float: left;
  width: 19%;
  margin: 0 auto;
  text-align: center;
  input {
    font-size: 12px;
    color: #333;
    line-height: 36px;
  }
}
.right_num {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 12px;
  color: #333;
}
.content2 {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.tops {
  font-size: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
  }
  .gsbqw {
    font-size: 0;
    border-top: 1px solid #f3f3f3;
    div {
      float: left;
      // width: 7.69%;
      width: calc(100% / 13);
      height: 22px;
      font-size: 12px;
      vertical-align: top;
      border-left: 1px solid #f3f3f3;
      // border-top: 1px solid #eee;
    }
     div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
  }
  .box_top {
    display: inline-flex;
    flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:25%;
    height: 44px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 12px;
    vertical-align: top;
    line-height: 44px;
    box-sizing: border-box;
    h6 {
      font-weight: 600;
      font-size: 12px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 20px;
      line-height: 20px;
      font-weight: 500;
    }
    .money_box_top {
      width: 220px;
    }
  }
  .box_top2 {
    display: inline-flex;
    // flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:20%;
    height: 44px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 12px;
    vertical-align: top;
    line-height: 44px;
    box-sizing: border-box;

    h6 {
      font-weight: 600;
      font-size: 12px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    .money_box_top {
      width: 220px;
    }
  }
  .box_top3 {
    display: inline-flex;
    // flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:30%;
    height: 44px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 12px;
    vertical-align: top;
    line-height: 44px;
    box-sizing: border-box;

    h6 {
      font-weight: 600;
      font-size: 12px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    
    .money_box_top {
      width: 220px;
    }
  }
}
.tops .box_top:first-child {
  border-left: none !important;
}
.bottom {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 44px;
  position: relative;
  display: flex;
  p {
    font-size: 12px;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
  }
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
  }
}
.bottom:last-child {
  border-bottom: none;
}
.common_box {
  width: 25%;
  flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box2 {
  width: 20%;
  // flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box3 {
  width: 30%;
  // flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box:first-child {
  border-left: 0;
}
.text_big {
  position: relative;
  height: 44px;
  li {
    float: left;
    width: calc(100% / 13);
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 12px;
    color: #333;
    vertical-align: top;
    border-left: 1px solid #f3f3f3;
    z-index: 8;
    position: relative;
    font-weight: bold;
  }
  li:nth-child(1) {
    border-left: none;
  }
  input {
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.infarm_box {
  width: 100%;
  height: 44px;
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.td-money {
  width: 100%;
  text-align: center;
  position: relative;
  input {
    border: none !important;
    background: none !important;
    width: 100%;
    height: 43px;
    padding: 0;
    letter-spacing: 2px;
    text-align: right;
    font-size: 22px;
  }
  > div {
    width: 100% !important;
    text-align: right;
    height: 100%;
    // line-height: 67px;
    font-size: 12px;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 7px;
    top: 0;
  }
}
.bottom_input {
  width: 100%;
  margin: 0 auto;
  .item_btn {
    width: 46%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 80px;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
      text-align: right;
    }
    p {
      float: left;
      line-height: 36px;
      color: #333;
      font-size: 15px;
      font-weight: normal;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .left_box {
    width: 46%;
  }
  .right_box {
    width: 46%;
    // text-align: right;
  }
}
.big_boxcode .el-input__inner {
  width: 100%;
  // height: 67px !important;
  // line-height: 67px !important;
  border-radius: 0px !important;
  border: 1px solid #dcdfe6;
}
.big_boxwb .el-input__inner {
  height: 22px !important;
  line-height: 20px !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 0 10px !important;
  font-size: 12px;
}
.big_gsbqw {
  font-size: 0;
  border-top: 1px solid #eee;
  position: relative;
  height: 44px;
  p{
    position: absolute;
    left: 2px;
    top: 0;
    height: 44px;
    line-height: 44px !important;
    font-size: 12px;
    font-weight: normal !important;
    text-align: right !important;
    width: 100%;
  }
  div {
    float: left;
    width: calc(100% / 13);
    height: 43px;
    font-size: 12px;
    vertical-align: top;
    border-left: 1px solid #eee;
  }
  div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.boss {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  .iconfont icon-cuowu{
    position: absolute;
    right: 2px;
    top: 2px;
    color: #f56c6c;
  }
  .boss-list{
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.subjectWidth{
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content {
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 5px;
    color: #333;
    font-size: 13px;
  }
}
.el-select-dropdown__item{
  display: flex;
  align-items: center;
}
.pic_mark_type{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-size: 14px;
  color: orange;
}
.icon-jianpan {
  position: absolute;
  top: 10px;
  left: 30px;
}
</style>

<style>
.input-height .el-input__inner{
    height: 43px;
}
.input-height .n-base-selection-label, .n-base-selection-input{
  height: 43px;
}

</style>
