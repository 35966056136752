<template>
  <div class="auto_home">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 40px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zidong"></i>
          </div>
          <div class="each_text">
            <div>授权在线</div>
            <span>{{ info.countEmpower }}</span>家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a><span>{{ info.countYc }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div class="each_text">
            <div>已完成</div>
            <a>
              <span>{{ info.countOk }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(6)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div class="each_text">
            <div>未完成</div>
            <a>
              <span>{{ info.countWwc }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-lishishuju1"></i>
          </div>
          <div class="each_text">
            <div>进行中</div>
            <a>
              <span>{{ info.countJxz }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(3)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(4)">
          <div class="icon_bg">
            <i class="iconfont icon-chushihuacaozuo"></i>
          </div>
          <div class="each_text">
            <div>未开始</div>
            <a>
              <span>{{ info.countWks }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(5)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>失败</div>
            <a>
              <span>{{ info.countErr }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="amountVisible = true">
          <div class="icon_bg">
            <i class="iconfont icon-baobiaotongji"></i>
          </div>
          <div class="each_text">
            <div>剩余余额</div>
            <a>
              <span>{{ (coins + coinsGive).toFixed(2) }}</span>元
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px;margin:0 5px"
          @change="getNewList"></qzf-search-period>
      </div>
      <el-button size="small" type="primary" @click="goStatistics" icon="Collection" v-if="activeName != '停报客户'"
        style="position: relative;right: 12px;top: 13px;z-index: 9;float: right;">预计人工降耗金额</el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane name="税款申报">
        <template #label>
          税款申报({{ info.countSkSb }})
        </template>
      </el-tab-pane>
      <el-tab-pane name="零申报">
        <template #label>
          零申报({{ info.countLsb }})
        </template>
      </el-tab-pane>
      <el-tab-pane name="停报客户" label="停报客户">
      </el-tab-pane>
      <div class="top_select">
        <div class="top_select_left">
          <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
          <el-input size="small" placeholder="公司名称" v-model.trim="listQuery.name" style="width:160px;"
            @keyup.enter="getList" clearable></el-input>
          <el-button size="small" type="primary" @click="getList" icon="Search" class="search_btn">
            搜索
          </el-button>
          <search @success="getList" @cancel="cancel">
            <el-scrollbar :maxHeight="350">
              <el-form style="margin:11px 0px 0px 15px;width:670px" label-width="130px" class="style_form" size="small"
                :inline="true">
                <el-form-item label="纳税人类型：">
                  <selecttaxtype v-model:type="listQuery.type" class="w-140"></selecttaxtype>
                </el-form-item>
                <el-form-item label="公司业务类型：" v-if="activeName != '停报客户'">
                  <select-common placeholder="请选择公司业务类型" v-model:modelValue="listQuery.ywlx" :options="ywlxOptions"
                    class="w-140" />
                </el-form-item>
                <el-form-item label="选择人员：">
                  <selectuser code="bookkeeping" v-model:userId="listQuery.userId" class="w-140"></selectuser>
                </el-form-item>
                <el-form-item label="标签：">
                  <el-input v-model="listQuery.labelLogoEa" placeholder="请输入标签" clearable></el-input>
                </el-form-item>
                <el-form-item label="税局：">
                  <selectcity v-model:citys="listQuery.districtCode" class="w-140"></selectcity>
                </el-form-item>
                <el-form-item label="授权状态：">
                  <selectAccreditStatus v-model:type="listQuery.keepStatus" class="w-140">
                  </selectAccreditStatus>
                </el-form-item>
                <el-form-item label="清册状态：" v-if="activeName != '停报客户'">
                  <autoHomeSelect v-model:modelValue="listQuery.qcStatus" placeholder="请选择清册状态" />
                </el-form-item>
                <div v-if="activeName != '停报客户'">
                  <div>
                    <el-form-item label="勾选状态：">
                      <autoHomeSelect v-model:modelValue="listQuery.gxStatus" placeholder="请选择勾选状态" />
                    </el-form-item>
                  </div>
                  <el-form-item label="发票采集状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.fpcjStatus" placeholder="请选择发票采集状态"
                      loadingText="采集中" />
                  </el-form-item>
                  <el-form-item label="发票采集失败原因：">
                    <el-input v-model="listQuery.fpcjErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="银行采集状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.yhcjStatus" placeholder="请选择银行采集状态"
                      loadingText="采集中" />
                  </el-form-item>
                  <el-form-item label="银行采集失败原因：">
                    <el-input v-model="listQuery.yhcjErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="有无进项：">
                    <el-radio-group v-model="listQuery.inType">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">有进项</el-radio-button>
                      <el-radio-button label="0">无进项</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="有无销项：" label-width="113px">
                    <el-radio-group v-model="listQuery.outType">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">有销项</el-radio-button>
                      <el-radio-button label="0">无销项</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="有无银行：">
                    <el-radio-group v-model="listQuery.bankType">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">有银行</el-radio-button>
                      <el-radio-button label="0">无银行</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="有无工资：" label-width="113px">
                    <el-radio-group v-model="listQuery.salaryType">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">有工资</el-radio-button>
                      <el-radio-button label="0">无工资</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="单据确认状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.djqrStatus" placeholder="请选择单据确认状态" type="qr" />
                  </el-form-item>
                  <el-form-item label="单据确认失败原因：">
                    <el-input v-model="listQuery.djqrErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="个税采集状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.gscjStatus" placeholder="请选择个税采集状态"
                      loadingText="采集中" />
                  </el-form-item>
                  <el-form-item label="个税采集失败原因：">
                    <el-input v-model="listQuery.gscjErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="批量补充状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.plbcStatus" placeholder="请选择批量补充状态"
                      loadingText="补充中" />
                  </el-form-item>
                  <el-form-item label="批量补充失败原因：">
                    <el-input v-model="listQuery.plbcErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="生成凭证状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.scpzStatus" placeholder="请选择生成凭证状态"
                      loadingText="生成中" />
                  </el-form-item>
                  <el-form-item label="生成凭证失败原因：">
                    <el-input v-model="listQuery.scpzErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="结账状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.jzStatus" placeholder="请选择结账状态" loadingText="结账中" />
                  </el-form-item>
                  <el-form-item label="结账失败原因：">
                    <el-input v-model="listQuery.jzErr" clearable></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="质检确认状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.zjStatus" placeholder="请选择质检确认状态" type="zj" />
                  </el-form-item> -->
                  <div>
                    <el-form-item label="有无税金：">
                      <el-radio-group v-model="listQuery.taxType">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button label="1">有税金</el-radio-button>
                        <el-radio-button label="0">无税金</el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <el-form-item label="税款确认状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.skqrStatus" placeholder="请选择税款确认状态" type="skqr" />
                  </el-form-item>
                  <el-form-item label="税款确认失败原因：">
                    <el-input v-model="listQuery.skqrErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="申报状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.sbStatus" placeholder="请选择申报状态" loadingText="申报中" />
                  </el-form-item>
                  <el-form-item label="申报失败原因：">
                    <el-input v-model="listQuery.sbErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="扣款状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.kkStatus" placeholder="请选择扣款状态" loadingText="扣款中" />
                  </el-form-item>
                  <el-form-item label="扣款失败原因：">
                    <el-input v-model="listQuery.kkErr" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="检查状态：">
                    <autoHomeSelect v-model:modelValue="listQuery.jcStatus" placeholder="请选择检查状态" loadingText="检查中" />
                  </el-form-item>
                  <el-form-item label="检查失败原因：">
                    <el-input v-model="listQuery.jcErr" clearable></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </el-scrollbar>
          </search>
          <div class="hint_style top_select_left" v-show="activeName == '税款申报'" @click="toSet"><i
              class="iconfont icon-guangbo"></i>
            {{ autoStartTime ? `已设置每月${autoStartTime}日启动任务` : `未设置启动时间时,系统默认 ${taxStart} 号启动,${taxEnd}号结束` }}
            ，<a class="set_style">点击{{ autoStartTime ? '修改' : '设置' }}</a>
          </div>
        </div>
        <div v-if="activeName != '停报客户'">
          <colSetting name="记账报税" btnName="显示列" @arrangeInfo="getArrangeInfo" />
          <el-button size="small" type="success" @click="reStart" icon="SwitchButton" v-if="autoButtonStatus"
            :disabled="havingTask || !$buttonStatus('zdjz_cq')" :loading="havingTask">重启</el-button>
          <el-button size="small" type="success" @click="autoStart" :disabled="havingTask || !$buttonStatus('zdjz_ts')"
            v-if="autoButtonStatus"><i class="iconfont icon-a-xinjianzidingyichicun21x1"
              :loading="havingTask"></i>调试</el-button>
          <el-button size="small" type="success" @click="daochu" icon="Folder" :loading="daochuLoading"
            :disabled="!$buttonStatus('zdjz_dc')">导出</el-button>
          <el-dropdown size="small" :hide-on-click="false" style="margin-left: 12px;">
            <el-button size="small" type="primary">
              更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu style="text-align:center">
                <el-dropdown-item command="a" v-if="$buttonStatus('jzkh_plsz')">
                  <span @click="setAll">批量设置</span>
                </el-dropdown-item>
                <el-dropdown-item command="h" v-if="$buttonStatus('jzkh_yjpx')">
                  <span @click="oneKeySort">一键排序</span>
                </el-dropdown-item>
                <el-dropdown-item command="b" v-if="$buttonStatus('jzkh_sbzq')">
                  <span @click="updateCycle">修改年报申报周期</span>
                </el-dropdown-item>
                <el-dropdown-item command="c" v-if="$buttonStatus('sjxx_xzsz')">
                  <span @click="addTax">新增税种</span>
                </el-dropdown-item>
                <el-dropdown-item command="c" v-if="$buttonStatus('jzkh_szqyzt')">
                  <span @click="updateState">修改税种启用状态</span>
                </el-dropdown-item>
                <el-dropdown-item command="d" v-if="$buttonStatus('jzkh_qych')">
                  <span @click="updateStock">设置是否启用存货</span>
                </el-dropdown-item>
                <el-dropdown-item command="e">
                  <span @click="updateProducer">修改制作人审核人</span>
                </el-dropdown-item>
                <el-dropdown-item command="f">
                  <span @click="setLabel">设置标签</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <vxe-grid
        border
        stripe
        auto-resize
        size="small"
        :columns="columns"
        :data="list"
        v-loading="loading"
        :show-footer="true"
        :height="contentStyleObj"
        :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }"
        :scroll-x="{ enabled: true, gt: 0 }"
        :show-overflow="true"
        :row-config="{ height: this.activeName != '停报客户' ? 71 : 34 }"
        :column-config="{ resizable: true }"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <template #sortSlot="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
        <template #name="scope">
          <TagNameCopy :row="scope.row" :idKey="activeName == '停报客户' ? 'id' : 'comId'"
            :showAuthIcon="activeName == '停报客户' ? false : true">
            <template v-slot v-if="activeName != '停报客户'">
              <el-tooltip content="做账习惯配置" placement="top" effect="dark">
                <i class="iconfont icon-guanlishezhi" v-if="$buttonStatus('zdjz_xgpz')"
                  style="color:var(--themeColor,#17a2b8);cursor: pointer;font-size: 15px;margin-left:8px"
                  @click="settingOne(scope.row)">
                </i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
        <template #qc_status="scope">
          <div class="item_icon">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.qc_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.qc_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.qc_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.qc_status)">{{
                    $filterAutoStatusText(scope.row.qc_status)
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.qc_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.qc_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
          </div>
        </template>
        <template #gx_status="scope">
          <div class="item_icon" v-if="scope.row.type == '2'">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.gx_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.gx_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.gx_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.gx_status)">{{
                    $filterAutoStatusText(scope.row.gx_status)
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.gx_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.gx_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'gx','勾选')" ></i> -->
          </div>
          <div v-else>
            --
          </div>
        </template>
        <template #fpcj_status="scope">
          <div class="item_icon div_p">
            <p>
              发票：
              <i :class="$filterAutoStatusIcon(scope.row.fpcj_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.fpcj_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.fpcj_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.fpcj_status)">{{
                    $filterAutoStatusText(scope.row.fpcj_status,
                      'cj') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.fpcj_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-button @click="checkInvoice(scope.row)" :disabled="!$buttonStatus('zdjz_ck')" size="small" link
              style="padding: 4px 3px;margin-left: 0;">查看</el-button>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                style="margin-left: 2px;" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'fpcj','发票采集')" ></i> -->
          </div>
          <div class="item_icon div_p">
            <p>
              银行：
              <i :class="$filterAutoStatusIcon(scope.row.yhcj_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.yhcj_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.yhcj_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.yhcj_status)">{{
                    $filterAutoStatusText(scope.row.yhcj_status, 'cj') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.yhcj_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-button @click="checkBank(scope.row)" :disabled="!$buttonStatus('zdjz_ck')" size="small" link
              style="padding: 4px 3px;margin-left: 0;">查看</el-button>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                style="margin-left: 2px;" v-if="scope.row.yhcj_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'yhcj','银行采集')" ></i> -->
          </div>
        </template>
        <template #djqr_status="scope">
          <div class="item_icon">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.djqr_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.djqr_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.djqr_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.djqr_status)">{{
                    $filterAutoStatusText(scope.row.djqr_status,
                      'qr') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.djqr_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
              <el-button @click="confirmAuto(scope.row, 'djqr', '单据确认')" v-if="scope.row.djqr_status == 2"
                :disabled="!$buttonStatus('zdjz_sydjqr')" size="small" link
                style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.djqr_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!--  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'djqr','单据确认')" v-if="scope.row.djqr_status!=3 && scope.row.djqr_status!=6 && scope.row.djqr_status!=7"></i> -->
          </div>
        </template>
        <template #gscj_status="scope">
          <div class="item_icon div_p">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.gscj_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.gscj_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.gscj_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.gscj_status)">{{
                    $filterAutoStatusText(scope.row.gscj_status,
                      'cj') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.gscj_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-button @click="checkGs(scope.row)" size="small" link
                style="padding: 4px 3px;margin-left: 0;">查看</el-button>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                  style="margin-left: 2px;" v-if="scope.row.gscj_status == 4" @click="continueTask(scope.row)"><i
                    class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
            </div>
            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'fpcj','发票采集')" v-if="scope.row.fpcj_status!=3 && scope.row.fpcj_status!=6 && scope.row.fpcj_status!=7"></i> -->
          </div>
        </template>
        <template #bc_status="scope">
          <div class="item_icon">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.bc_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.bc_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.bc_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.bc_status)">{{
                    $filterAutoStatusText(scope.row.bc_status, 'bc')
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.bc_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.bc_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'bc','批量补充')" v-if="scope.row.bc_status!=3 && scope.row.bc_status!=6 && scope.row.bc_status!=7"></i> -->
          </div>
        </template>
        <template #pz_status="scope">
          <div class="item_icon">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.pz_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.pz_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.pz_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.pz_status)">{{
                    $filterAutoStatusText(scope.row.pz_status, 'sc')
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.pz_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.pz_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!--   <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'pz','生成凭证')" v-if="scope.row.pz_status!=3 && scope.row.pz_status!=6 && scope.row.pz_status!=7"></i> -->
          </div>
        </template>
        <template #jz_status="scope">
          <div class="item_icon">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.jz_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.jz_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.jz_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.jz_status)">{{
                    $filterAutoStatusText(scope.row.jz_status, 'jz')
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.jz_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.jz_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>
            <!--   <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'jz','结账')" v-if="scope.row.jz_status!=3 && scope.row.jz_status!=6 && scope.row.jz_status!=7"></i> -->
          </div>
        </template>
        <template #skqr_status="scope">
          <div class="item_icon div_p">
            <p>
              确认：
              <i :class="$filterAutoStatusIcon(scope.row.skqr_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.skqr_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.skqr_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.skqr_status)">{{
                    $filterAutoStatusText(scope.row.skqr_status,
                      'skqr') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.skqr_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-button @click="confirmAuto(scope.row, 'skqr', '税款确认')" v-if="scope.row.skqr_status == 2"
              :disabled="!$buttonStatus('zdjz_syskqr')" size="small" link
              style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
            <el-button @click="checkTableDialog(scope.row, '')" size="small" link
              style="padding: 4px 3px;margin-top: -1px;margin-left: 0;"
              :disabled="scope.row.skqr_status == 0 || !$buttonStatus('zdjz_ck')">查看</el-button>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.skqr_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>

            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'skqr','税款确认')" v-if="scope.row.skqr_status!=3 && scope.row.skqr_status!=6 && scope.row.skqr_status!=7"></i> -->
          </div>
          <div class="item_icon div_p">
            <p>
              申报：
              <i :class="$filterAutoStatusIcon(scope.row.sb_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.sb_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.sb_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.sb_status)">{{
                    $filterAutoStatusText(scope.row.sb_status, 'sb')
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.sb_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-button @click="checkTableDialog(scope.row, 'sb')" size="small" link
                style="padding: 4px 3px;margin-top: -1px;"
                :disabled="scope.row.sb_status == 0 || !$buttonStatus('zdjz_ck')">查看</el-button>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                  style="margin-left: 0px;" v-if="scope.row.sb_status == 4" @click="continueTask(scope.row)"><i
                    class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" placement="top-start" content="跳过申报">
                <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row, 'sb', '申报')"
                  v-if="scope.row.qc_status && scope.row.sb_status != 3 && scope.row.sb_status != 6 && scope.row.sb_status != 7"></i>
              </el-tooltip>
            </div>
          </div>
        </template>
        <template #kkqr_status="scope">
          <div class="item_icon div_p">
            <p>
              确认：
              <i :class="$filterAutoStatusIcon(scope.row.kkqr_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.kkqr_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.kkqr_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.kkqr_status)">{{
                    $filterAutoStatusText(scope.row.kkqr_status,
                      'qr') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.kkqr_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <el-button @click="confirmAuto(scope.row, 'kkqr', '扣款确认')" v-if="scope.row.kkqr_status == 2"
              :disabled="!$buttonStatus('zdjz_syskqr')" size="small" link
              style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
            <el-button @click="checkTableDialog(scope.row, 'kk')" size="small" link
              style="padding: 4px 3px;margin-top: -1px;margin-left: 0;"
              :disabled="scope.row.kkqr_status == 0 || !$buttonStatus('zdjz_ck')">查看</el-button>
            <el-tooltip effect="dark" placement="top-start" content="继续">
              <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                v-if="scope.row.kkqr_status == 4" @click="continueTask(scope.row)"><i
                  class="iconfont icon-jiantou-you"></i></el-button>
            </el-tooltip>

            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'skqr','税款确认')" v-if="scope.row.skqr_status!=3 && scope.row.skqr_status!=6 && scope.row.skqr_status!=7"></i> -->
          </div>
          <div class="item_icon div_p">
            <p>
              扣款：
              <i :class="$filterAutoStatusIcon(scope.row.kk_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.kk_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.kk_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.kk_status)">{{
                    $filterAutoStatusText(scope.row.kk_status,
                      'kk') }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.kk_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-button @click="checkTableDialog(scope.row, 'kk')" size="small" link
                style="padding: 4px 3px;margin-top: -1px;"
                :disabled="scope.row.kk_status == 0 || !$buttonStatus('zdjz_ck')">查看</el-button>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                  v-if="scope.row.kk_status == 4" @click="continueTask(scope.row)"><i
                    class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" placement="top-start" content="跳过扣款">
                <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row, 'kk', '扣款')"
                  v-if="scope.row.qc_status &&scope.row.kk_status != 3 && scope.row.kk_status != 6 && scope.row.kk_status != 7"></i>
              </el-tooltip>
            </div>
          </div>
        </template>
        <template #jc_status="scope">
          <div class="item_icon">
            <p>
              <span v-if="scope.row.image && [3, 4, 5].includes(scope.row.jc_status)">
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.jc_status == 4"
                  @click="open(scope.row, scope.$index, 'image')"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;"
                  v-if="scope.row.jc_status == 3" @click="open(scope.row, scope.$index, 'image')"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;"
                  v-if="scope.row.jc_status == 5" @click="open(scope.row, scope.$index), 'image'"></i>
              </span>
              <i v-else :class="$filterAutoStatusIcon(scope.row.jc_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.jc_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.jc_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.jc_status)">{{
                    $filterAutoStatusText(scope.row.jc_status, 'jc')
                  }}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.jc_err"
                    style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-tooltip effect="dark" placement="top-start" content="重启">
                <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.jc_status == 4"
                  @click="checkFailStart(scope.row)"><el-icon style="font-size: 15px">
                    <VideoPlay />
                  </el-icon></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')"
                  v-if="scope.row.jc_status == 4" @click="continueTask(scope.row)" style="margin-left: 0px"><i
                    class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
            </div>

            <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'jc','检查')" v-if="scope.row.jc_status!=3 && scope.row.jc_status!=6 && scope.row.jc_status!=7"></i> -->
          </div>
        </template>
        <template #stopTime="scope">
          {{ $parseTime(scope.row.stopTime, "{y}-{m}-{d}") }}
        </template>
        <template #gridData="scope">
          <DynamicPopover>
            <template #reference>
              <div @click="poper(scope.row)" style="cursor: pointer">
                <i class="iconfont icon-huiji"></i>
              </div>
            </template>
            <KjTable :list="gridData" />
          </DynamicPopover>
        </template>
        <template #opera="scope">
          <el-button @click="companyfp(scope.row)" size="small" type="primary" icon="User" plain
            :disabled="!$buttonStatus('jzkh_fp')">分配</el-button>
          <el-button @click="ceaseCompany(scope.row)" size="small" type="danger" icon="Warning" plain
            :disabled="!$buttonStatus('jzkh_fp')">停报</el-button>
        </template>
      </vxe-grid>
      <div style="float:left;margin-top:16px" v-if="activeName != '停报客户'">
        <el-button size="small" type="primary" plain @click="companyfpAll" :disabled="!$buttonStatus('jzkh_fp')"
          icon="User">
          批量分配
        </el-button>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
          @pagination="getList" type="autoHomeIndex" />
      </div>
    </el-tabs>
  </div>
  <!-- 启动 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="启动方式" width="400px">
    <el-form>
      <el-form-item label="启动方式：">
        <el-select v-model="startType" placeholder="请选择启动方式" size="small" style="width:140px;">
          <el-option label="从头启动" :value="0"></el-option>
          <!-- <el-option label="从失败处启动" :value="1"></el-option> -->
          <el-option label="只记账" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="startSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 批量存货 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="stockVisible" title="开启存货设置" width="400px">
    <el-form :model="stockForm">
      <el-form-item label="是否开启：" :label-width="formLabelWidth">
        <el-radio-group v-model="stockForm.inventoryStatus">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="stockVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="stockSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 制作人 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="producerVisible" title="制作人审核人" width="550px">
    <div class="right_title none-border">
      <p>*温馨提示：为空默认登录人！</p>

      <div style="margin-bottom:20px;margin-left: 30px;">
        制作人：<el-input style="width:200px;" v-model="mergeSetInfo.createUser" placeholder="制作人" size="small"></el-input>
        隐藏：
        <el-switch :active-value="1" :inactive-value="0" v-model="mergeSetInfo.createUserStatus"></el-switch>
        （仅在打印时）
      </div>
      <div style="margin-left: 30px;">
        审核人：<el-input style="width:200px;" v-model="mergeSetInfo.examUser" placeholder="审核人" size="small"></el-input>
        隐藏：
        <el-switch :active-value="1" :inactive-value="0" v-model="mergeSetInfo.examUserStatus"></el-switch>
        （仅在打印时）
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="producerVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="producerSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    v-model="amountVisible"
    title="查看余额"
    width="500px"
  >
    <table class="content" cellspacing="0" width="100%" >
      <tr class="top_bg center">
        <td style="width:50%;">充值金额</td>
        <td style="width:50%;">赠送金额</td>
      </tr>
      <tr class="center">
        <td >{{coins}}</td>
        <td>{{ coinsGive }}</td>
      </tr>
    </table>
  </el-dialog>
  <autoTaxDetail ref="autoTaxDetail" />
  <collectInvoiceDetail ref="collectInvoiceDetail" />
  <sbImage ref="sbImage" />
  <bankDeatils ref="bankDeatils" />
  <abnormalCom ref="abnormalCom" />
  <!-- 统计 -->
  <aiStatistics ref="aiStatistics"></aiStatistics>
  <companyFp ref="companyFp" code="bookkeeping" @success="getList"></companyFp>
  <stopCompany ref="stopCompany" @success="getList" from="automatic" />
  <companySet ref="companySet" idKey="comId" />
  <!-- 习惯配置 -->
  <autoSetOne ref="autoSetOne" />
  <!-- 批量修改税种申报周期 -->
  <taxCategoryCycle ref="taxCategoryCycle" />
  <!-- 新增2税种 -->
  <batchAddCategory ref="batchAddCategory" />
  <!-- 修改税种启用状态 -->
  <taxCategoryState ref="taxCategoryState" />
  <declareDetails ref="declareDetails"></declareDetails>
  <companySort ref="companySort" @success="getList" :automatic="1" />
  <!-- 税款确认选择器 -->
  <taxVerifySelect ref="taxVerifySelectRef" @success="getList" />
</template>

<script>
import { companyAutomaticList, startUpAutomatic, jumpAutomatic, confirmAutomatic, testAuto, automaticStat, confirmAutomaticBill, handSure,automaticBalance } from "@/api/automatic";
import { currentAccountPeriod } from '@/utils'
import { aiExportAssignCustomers } from '@/api/export'
import { companyUserList, eaCompanyList, companyEaSetLable } from '@/api/company'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import collectInvoiceDetail from "../batch/components/collectInvoiceDetail.vue";
import autoTaxDetail from './components/autoTaxDetail.vue'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import sbImage from '@/components/sbImage'
import bankDeatils from "./components/bankDeatils.vue";
import abnormalCom from './components/abnormalCom.vue'
import aiStatistics from "../layout/components/aiStatistics.vue";
import DynamicPopover from "@/components/DynamicPopover/DynamicPopover";
import KjTable from "@/components/table-cell-render/KjTable";
import companyFp from "../company/components/companyFp.vue";
import autoHomeSelect from "./components/autoHomeSelect.vue";
import stopCompany from "../company/components/stopCompany.vue";
import companySet from "../company/components/companySet.vue";
import autoSetOne from './components/autoSetOne.vue'
import taxCategoryCycle from '../company/components/taxCategoryCycle.vue'
import batchAddCategory from '../company/components/batchAddCategory.vue'
import taxCategoryState from '../company/components/taxCategoryState.vue'
import { inventoryStatusSetting } from '@/api/org'
import { batchMergeSet } from "@/api/voucher"
import declareDetails from "../batch/components/declareDetails.vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import companySort from "../company/components/companySort.vue";
import taxVerifySelect from './components/taxVerifySelect.vue'
export default {
  name: "automaticZwzx",
  components: {
    selecttaxtype,
    selectcity,
    selectAccreditStatus,
    autoTaxDetail,
    collectInvoiceDetail,
    TagNameCopy,
    sbImage,
    bankDeatils,
    abnormalCom,
    aiStatistics,
    DynamicPopover,
    KjTable,
    companyFp,
    autoHomeSelect,
    stopCompany,
    companySet,
    autoSetOne,
    taxCategoryCycle,
    batchAddCategory,
    taxCategoryState,
    declareDetails,
    TableSortCell,
    companySort,
    taxVerifySelect
  },
  props: {},
  data() {
    return {
      mergeSetInfo: {
        createUser: '',
        createUserStatus: 0,
        examUser: '',
        examUserStatus: 0,
        comIds: []
      },
      producerVisible: false,
      stockVisible: false,
      stockForm: {},
      autoButtonStatus: this.$store.getters["user/user"].autoButtonStatus,
      roles:this.$store.getters["user/roles"],
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        period: currentAccountPeriod(),
        accountingType: 2,
        statusTotal: null,
        ywlx: 6,
        labelLogoEa:'',
      },
      contentStyleObj: {},
      list: [],
      total: 0,
      loading: false,
      sels: [],
      startType: 0,
      dialogTableVisible: false,
      activeName: '税款申报',
      daochuLoading: false,
      pageStatus: true,
      havingTask: false,
      info: {
        countTotal: 0,
        countEmpower: 0,
        countOk: 0,
        countDqr: 0,
        countErr: 0,
        countJxz: 0,
        countWks: 0,
        countYc: 0,
        countLsb: 0,
        countSkSb: 0,
        countWwc: 0
      },
      gridData: [],
      ywlxOptions: [
        {
          label: "全部",
          value: 6,
        },
        {
          label: "服务类",
          value: 0,
        },
        {
          label: "贸易类",
          value: 1,
        },
        {
          label: "生产类",
          value: 2,
        },
        {
          label: "工程类",
          value: 3,
        },
      ],
      taxEnd: "",
      taxStart: "",
      originPeriod: "",
      autoStartTime: "",
      arrangeInfo: ["编号", '标签'],
      coins:0,
      coinsGive:0,
      amountVisible:false
    };
  },

  computed: {
    columns() {
      return [
        { type: 'checkbox', width: 60, fixed: 'left' },
        ...this.arrangeInfo.includes('编号') ? [{
          title: '编号',
          field: 'sortable',
          sortable: true,
          width: 80,
          fixed: 'left',
          slots: {
            default: 'sortSlot',
          }
        }] : [],
        ...this.arrangeInfo.includes('标签') ? [{
          title: '标签',
          field: 'labelLogoEa',
          width: 70,
          align: 'center',
          fixed: 'left',
        }] : [],
        {
          title: "公司名称",
          field: "name",
          fixed: 'left',
          minWidth: 240,
          slots: {
            default: 'name',
          }
        },
        ...this.activeName != '停报客户' ? [{
          title: "初始化",
          field: "csh",
          headerAlign: 'center',
          children: [
            {
              field: "qc_status",
              title: "清册",
              width: 100,
              align: 'left',
              slots: {
                default: 'qc_status'
              }
            },
            ...this.activeName != '停报客户' ? [{
              field: "gx_status",
              title: "勾选",
              width: 100,
              align: 'left',
              slots: {
                default: 'gx_status'
              }
            }] : []
          ]
        }] : [],
        ...this.activeName != '停报客户' ? [
          {
            title: "自动单据采集",
            field: "zddjcj",
            headerAlign: 'center',
            children: [
              {
                field: "fpcj_status",
                title: "发票/银行采集",
                width: 170,
                align: 'left',
                slots: {
                  default: 'fpcj_status'
                }
              },
              ...this.activeName != '停报客户' ? [{
                field: "djqr_status",
                title: "单据确认",
                width: 120,
                align: 'left',
                slots: {
                  default: 'djqr_status'
                }
              }, {
                field: "gscj_status",
                title: "累计/专项扣除采集",
                width: 170,
                align: 'left',
                slots: {
                  default: 'gscj_status'
                }
              }] : [],
            ],
          },
        ] : [],
        ...this.activeName != '停报客户' ? [
          {
            title: "Ai账务处理",
            field: "aizwcl",
            headerAlign: 'center',
            children: [
              {
                field: "bc_status",
                title: "批量补充",
                width: 100,
                align: 'left',
                slots: {
                  default: 'bc_status'
               }
              },
              ...this.activeName != '停报客户' ? [{
                field: "pz_status",
                title: "生成凭证",
                width: 100,
                align: 'left',
                slots: {
                  default: 'pz_status'
                }
              }, {
                field: "jz_status",
                title: "结账",
                width: 100,
                align: 'left',
                slots: {
                  default: 'jz_status'
                }
              }, {
                field: "skqr_status",
                title: "申报扣款确认",
                width: 175,
                align: 'left',
                slots: {
                  default: 'skqr_status'
                }
              }] : [],
            ]
          }
        ] : [],
        ...this.activeName != '停报客户' ? [{
          title: "国税报税/检查",
          field: "gsbsjc",
          headerAlign: 'center',
          children: [
            {
              field: "kkqr_status",
              title: "扣款确认",
              width: 175,
              align: 'left',
              slots: {
                default: 'kkqr_status'
              }
            },
            ...this.activeName != '停报客户' ? [{
              field: "jc_status",
              title: "检查",
              width: 175,
              align: 'left',
              slots: {
                default: 'jc_status'
              }
            }] : [],
          ]
        }] : [],
        ...this.activeName === '停报客户' ? [{
          field: "startAccountPeriod",
          title: "启用账期",
          width: 100,
          align: 'left',
        }, {
          field: "CurPeriod",
          title: "当前账期",
          width: 100,
          align: 'left',
        }, {
          field: "districtName",
          title: "税局",
          width: 100,
          align: 'left',
        }, {
          field: "stopTime",
          title: "停报时间",
          minWidth: 110,
          align: 'left',
          slots: {
            default: 'stopTime',
          }
        }, {
          field: "remark",
          title: "停报原因",
          minWidth: 100,
          align: 'left',
        }] : [],
        {
          title: "会计",
          field: "gridData",
          minWidth: 100,
          align: 'center',
          slots: {
            default: 'gridData',
          }
        },
        ...this.activeName != '停报客户' ? [{
          title: "操作",
          field: "opera",
          width: 180,
          align: 'center',
          slots: {
            default: 'opera',
          }
        }] : []
      ]
    }
  },
  activated() {
    this.pageStatus = true
  },
  deactivated() {
    this.pageStatus = false
  },
  beforeRouteLeave(to, from, next) {
    this.pageStatus = false
    next()
    // ...
  },
  created() {
    this.listQuery.limit = localStorage.getItem("autoHomeIndex")
      ? localStorage.getItem("autoHomeIndex") * 1
      : 20;
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(326);
    if(localStorage.getItem('statusType')){
      this.listQuery.djqrStatus = localStorage.getItem('statusType')*1;
    }
    this.getList();
    this.initBus();
    this.init()
    this.getInfo();
    this.getAmount()
    localStorage.removeItem('statusType');
  },
  methods: {
    //获取余额
    getAmount(){
      automaticBalance({}).then(res=>{
        if(res.data.msg == 'success'){
          this.coins = res.data.data.coins
          this.coinsGive = res.data.data.coins_give
        }
      })
    },
    // 制作人审核人弹框
    updateProducer() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.producerVisible = true
    },
    getArrangeInfo(e) {
      this.arrangeInfo = e
      this.getList()
    },
    producerSure() {
      this.mergeSetInfo.comIds = [];
      this.sels.map((v) => {
        this.mergeSetInfo.comIds.push(v.comId);
      });
      batchMergeSet(this.mergeSetInfo).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage('设置成功');
          this.producerVisible = false
        }
      })
    },
    // 是否启用年货
    updateStock() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.stockForm.ids = this.sels.map(v => {
        return v.comId
      })
      this.stockForm.inventoryStatus = 1
      this.stockVisible = true
    },
    stockSure() {
      inventoryStatusSetting(this.stockForm).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage('设置成功');
          this.getList()
          this.stockVisible = false
        }
      })
    },
    //修改税种启用状态
    updateState() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$refs.taxCategoryState.init(this.sels, '财务工厂')
    },
    //新增税种
    addTax() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      const ids = this.sels.map(v => {
        return v.comId
      })
      this.$refs.batchAddCategory.init(ids)
    },
    //批量修改税种申报周期
    updateCycle() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$refs.taxCategoryCycle.init(this.sels, '财务工厂')
    },
    // 统计
    goStatistics() {
      this.$refs.aiStatistics.init()
    },
    init() {
      this.$bus.off("confirmStatusUpdate")
      this.$bus.on("confirmStatusUpdate", (val) => {
        setTimeout(() => {
          this.getList()
          this.getInfo()
        }, 2000)
      });
    },
    getInfo() {
      automaticStat(this.listQuery).then(res => {
        if (res.data.msg == 'success') {
          this.info = res.data.data
        }
      })
    },
    getNewList() {
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e) {
      if (this.activeName == '停报客户') return
      this.listQuery.statusTotal = e ? e : null
      this.getNewList()
    },
    initBus() {
      this.$bus.off("refreshTask")
      this.$bus.on("refreshTask", (val) => {
        if (!this.pageStatus) {
          return
        }
        if (this.sels.length != 0) {
          return
        }
        if (this.list.length == 0) {
          return
        }
        if (this.activeName == '停报客户') return
        companyAutomaticList(this.listQuery).then(res => {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.taxStart = res.data.data.taxStart;
          this.taxEnd = res.data.data.taxEnd;
          this.autoStartTime = res.data.data.autoStartTime;
        })
      });
    },
    getList() {
      this.sels = []
      this.loading = true
      if (this.activeName == '停报客户') {
        this.getStopList()
      } else {
        companyAutomaticList(this.listQuery).then((res) => {
          this.loading = false
          if (res.data.msg == "success") {
            this.list = res.data.data.list ? res.data.data.list : [];
            this.total = res.data.data.total;
            this.taxStart = res.data.data.taxStart;
            this.taxEnd = res.data.data.taxEnd;
            this.autoStartTime = res.data.data.autoStartTime;
          }
        });
      }
    },
    //单独设置
    settingOne(row) {
      this.$refs.autoSetOne.init(row)
    },
    handleSelectionChange({records}) {
      this.sels = records;
    },
    //启动
    autoStart() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1)
        return
      }
      this.dialogTableVisible = true;
      this.startType = 0
    },
    startSure() {
      let ids = []
      this.sels.map(v => {
        ids.push(v.comId)
      })
      let param = {
        comIds: ids,
        period: this.listQuery.period,
        type: this.startType
      }
      this.startTask(param)
    },
    //继续--失败处启动
    continueTask(row) {
      this.$confirm('确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds: [row.comId],
          period: this.listQuery.period,
          type: 1
        }
        this.startTask(param, row)
      });
    },
    //检查失败可以重启
    checkFailStart(row) {
      this.$confirm('确定重启吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds: [row.comId],
          period: this.listQuery.period,
          type: 0
        }
        this.startTask(param, row)
      });
    },
    //重启
    reStart() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1)
        return
      }
      let ids = []
      this.sels.map(v => {
        ids.push(v.comId)
      })
      let param = {
        comIds: ids,
        period: this.listQuery.period,
        type: 0
      }
      this.startTask(param)
    },
    startTask(param, row) {
      if (row) {
        row.btnStatus = true
        setTimeout(() => {
          row.btnStatus = false
        }, 10 * 1000)
      }
      this.havingTask = true
      setTimeout(() => {
        this.havingTask = false
      }, 10 * 1000)
      startUpAutomatic(param).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage('任务发起成功')
          this.dialogTableVisible = false
          this.getList()
          setTimeout(() => {
            companyAutomaticList(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                this.list = res.data.data.list ? res.data.data.list : [];
                this.total = res.data.data.total;
              }
            });
            this.getInfo()
          }, 5000)
        }
      })
    },
    //跳过步骤
    skipOption(row, type, text) {
      this.$confirm('确定要跳过' + text + '吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        jumpAutomatic({
          comId: row.comId,
          period: this.listQuery.period,
          type: type
        }).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage('成功跳过');
            this.getList()
          }
        })
      });
    },
    confirmRg(row) {
      this.$confirm("确定人工确认吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          comIds: [row.comId],
          period: this.listQuery.period,
          handStatus: 1,
        };
        handSure(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("已确认");
            this.getList();
          }
        });
      });
    },
    //确认
    confirmAuto(row, type, text) {
      if (type == 'skqr') {
        this.$refs.taxVerifySelectRef.init([row], this.listQuery.period)
      } else {
        this.$confirm('确定要' + text + '吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          confirmAutomatic([{
            comId: row.comId,
            period: this.listQuery.period,
            type: type
          }]).then(res => {
            if (res.data.msg == 'success') {
              this.$qzfMessage('操作成功');
              this.getList()
              this.$bus.emit('confirmStatusUpdate')
            }
          })
        });
      }

    },
    //单据确认
    confirmBill(row, type, text) {
      this.$confirm('确定要' + text + '吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomaticBill([{
          comId: row.comId,
          period: this.listQuery.period,
          type: type
        }]).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage('操作成功');
            this.getList()
          }
        })
      });
    },
    //申报查看
    checkTableDialog(row, type) {
      this.$refs.autoTaxDetail.init(row, this.listQuery.period, type)
    },
    //单据确认查看发票list
    checkInvoice(row) {
      this.$refs.collectInvoiceDetail.init(row, this.listQuery.period)
    },
    //单据确认查看银行list
    checkBank(row) {
      this.$refs.bankDeatils.init(row, this.listQuery.period)
    },
    //账套迁出
    moveOut() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1)
        return
      }
      this.$confirm('账套迁出后，将取消本企业自动化记账报税，本账期已扣费企业，扣费不再返还并且会清除所有任务记录，确认迁出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        this.sels.map(v => {
          ids.push(v.comId)
        })
        let param = {
          comIds: ids,
          type: '迁出'
        }
        testAuto(param).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("迁出成功")
            this.getNewList()
          }
        })
      });
    },
    handleClick(tab) {
      this.activeName = tab.props.name;
      if (tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
        this.listQuery.period = this.listQuery.period || this.originPeriod
        this.listQuery.status = '2'
      } else if (tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
        this.listQuery.period = this.listQuery.period || this.originPeriod
        this.listQuery.status = '2'
      } else {
        this.listQuery.accountingType = null
        this.listQuery.status = "3"
        this.originPeriod = this.listQuery.period
        this.listQuery.period = ""
      }
      this.getList()
    },
    getStopList() {
      eaCompanyList(this.listQuery).then(res => {
        this.loading = false
        if (res.data.msg == 'success') {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      })
    },
    cancel() {
      let originLimit = this.listQuery.limit
      let originAccountingType = this.listQuery.accountingType
      let originStatus = this.listQuery.status
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        period: currentAccountPeriod(),
        accountingType: originAccountingType,
        ywlx: 6,
        status: originStatus
      }
      if (this.activeName == '停报客户') {
        this.listQuery.accountingType = null
        this.listQuery.status = "3"
        this.originPeriod = this.listQuery.period
        this.listQuery.period = ""
      }
      this.getList()
    },
    open(row, index, flag) {
      let imageAll = []
      this.list.map(res => {
        let arr = []
        if (res[flag].match(',')) {
          let imgUrls = res[flag].split(',')
          imgUrls.map(item => {
            if (item) {
              arr.push({
                img: 'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName: ""
              })
            }
          })
        } else {
          if (res[flag]) {
            arr.push({
              img: 'https://file.listensoft.net' + res[flag] + "?" + Math.random(10),
              tableName: ""
            })
          }
        }
        let url = {
          name: res.name,
          url: arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index, 0)
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period)
    },
    daochu() {
      this.daochuLoading = true
      let param = {
        query: this.listQuery,
        ids: this.sels.map(v => {
          return v.comId
        })
      }
      aiExportAssignCustomers(param).then(res => {
        this.daochuLoading = false
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      })
    },
    // 列表点击
    poper(row) {
      this.gridData = []
      companyUserList({ comId: row.comId }).then((res) => {
        this.gridData = res.data.data.list;
      });
    },
    //分配
    companyfp(row) {
      let info = Object.assign({}, row);
      info.id = row.comId;
      let ids = [row.comId]
      this.$refs.companyFp.init(ids, info)
    },
    //批量分配
    companyfpAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let ids = []
      this.sels.map(v => {
        ids.push(v.comId)
      })
      this.$refs.companyFp.init(ids)
    },
    toSet() {
      if(!this.roles.includes("automaticConfig")){
        this.$qzfMessage("无修改权限，请使用主账号分配权限", 1)
        return
      }
      this.$router.push({
        name: "automaticConfig",
      });
    },
    //停报
    ceaseCompany(row) {
      this.$refs.stopCompany.init([row])
    },
    //批量设置
    setAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$refs.companySet.init(this.sels)
    },
    //查看个税采集
    checkGs(row) {
      this.$refs.declareDetails.init(row.comId, this.listQuery.period, 'gs-cj-zxlj')
    },
    //一键排序
    oneKeySort() {
      let ids = []
      this.sels.map(v => {
        ids.push(v.comId)
      })
      this.$refs.companySort.init(ids)
    },
    setLabel() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$prompt('请输入标签', '设置标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            let value = instance.inputValue;
            let param = {
              ids: this.sels.map(v => v.comId),
              labelLogoEa: value
            }
            companyEaSetLable(param).then(res => {
              done();
              if (res.data.msg == "success") {
                this.$qzfMessage("设置成功")
                this.getList()
              }
            })
          } else {
            done()
          }
        }
      })
    },
    sortChange(data) {
      this.listQuery.sortCom = data.order == 'descending' ? 1 : 0
      this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.auto_content .auto_content_size .each{
  width:130px !important;
}
.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }

  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}

.text_width {
  width: 52px;
  display: inline-block
}

.icon-tiaoguo1 {
  color: #17a2b8;
}

.style_form .el-form-item {
  margin-bottom: 10px;

  .el-input {
    width: 140px;
    margin-left: 5px;
  }
}

.icon-a-xinjianzidingyichicun21x1 {
  font-size: 15px;
  margin-right: 2px;
}

.icon-jiantou-you {
  font-weight: 600;
}

.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    border-bottom: none;
  }
}
.auto_content_size {
  width: 91%;
  margin-left: 10px;
}

.top_select_left {
  display: flex;
  align-items: center;
}

.hint_style {
  padding: 3px 12px;
  margin-left: 10px;
  font-size: 14px;
  background-color: #fdf6ec;
  color: #e6a23c;
  border-radius: 4px;
  cursor: pointer;

  .icon-guangbo {
    margin-right: 3px;
  }
}

.set_style {
  text-decoration: underline;
}

.none-border p {
  line-height: 30px;
  text-align: left;
  text-indent: 1em;
  /// height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right_title {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;

  h3 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.right_title h5 {
  font-weight: normal;
}

.right_title p {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  .center{
    text-align: center;
  }
}
</style>
